
import axios                          from 'axios';
import { Vue, Component, Prop }       from 'vue-property-decorator';

import { CropData, UploadImage }      from '../interfaces';

import AdminImage                     from './AdminImage.vue';
import Croppable                      from './Croppable.vue';
import CircleCrop                     from './CircleCrop.vue';
import Rect167Crop                    from './Rect167Crop.vue';
import FourByThreeCrop                from './FourByThreeCrop.vue';

const getImageInfo = (imgdata: any) : Promise<{ width: number, height: number, extendColor?: string }> =>
    new Promise((resolve) => {
        const formData = new FormData();
        formData.append('image', imgdata)
        axios.post('/api/artdirector/1/upload/info', formData)
            .then(res => {
                resolve(res.data);
            });
    })

@Component({ components: { AdminImage, Croppable, CircleCrop, Rect167Crop, FourByThreeCrop } })
export default class ArtistUpload extends Vue {

    @Prop() artistId!:    string;
    @Prop() artistName!:  string;
    @Prop() artistType!:  'Group' | 'Individual' | null;

    step                                 = 0
    image:            UploadImage | null = null;
    uploadImage:      File | null        = null;
    uploadImage2:     Blob | null        = null;
    uploadId:         string | null      = null
    submitting                           = false;
    circleStartTime                      = 0;
    circleDuration                       = 0;
    circleResult                         = 'cropped';
    rect167Result                        = 'cropped';
    rect167StartTime                     = 0;
    rect167Duration                      = 0;
    rect43Result                        = 'cropped';
    rect43StartTime                     = 0;
    rect43Duration                      = 0;

    circleScale                          = "0";
    circleCrop:       CropData           = this.defaultCrop();
    rect167Scale                         = "0";
    rect167Crop:      CropData           = this.defaultCrop();
    rect43Scale                         = "0";
    rect43Crop:      CropData           = this.defaultCrop();

    urlChanging                          = false;

    loadImageURL() {
        this.urlChanging = true;

        // @ts-ignore
        const url = URL.createObjectURL(this.uploadImage || this.uploadImage2);

        getImageInfo(this.uploadImage || this.uploadImage2)
            .then(({ width, height, extendColor }) => {
                if ( width < 500 && height < 500 ) {
                    this.$toasted.error("Images must be at least 500x500");
                    this.reset();
                    this.urlChanging = false;
                    return;
                }
                this.image        = { url, imageinfo: { width, height, extendColor }, uploadId: '', status: '' };
                this.circleScale  = "0";
                this.circleCrop   = this.defaultCrop();
                this.rect167Scale = "0";
                this.rect167Crop  = this.defaultCrop();
                this.rect43Scale = "0";
                this.rect43Crop  = this.defaultCrop();
                this.$nextTick().then(() => {
                    // Add the component back in
                    this.urlChanging = false;
                });
            });

    }

    proceed(result: string, cb: () => any, direction: number = 1) {
        const nextStep = this.step + direction;
        //XXX need to save crop id or something to fix the same crop you just submitted
        switch ( nextStep ) {
            case 1:
                //circle crop start
                this.circleStartTime = new Date().getTime();
                break;
            case 2:
                //circle crop end, rect crop start
                if (direction > 0)
                    this.circleResult = result;
                this.circleDuration = new Date().getTime() - this.circleStartTime;
                this.rect167StartTime = new Date().getTime();
                break;
            case 3:
                //rect crop end, mobile crop start
                if (direction > 0)
                    this.rect167Result   = result;
                this.rect167Duration = new Date().getTime() - this.rect167StartTime;
                this.rect43StartTime = new Date().getTime();
                break;
            case 4:
                //mobile crop end, confirm start
                if (direction > 0)
                    this.rect43Result   = result;
                this.rect43Duration = new Date().getTime() - this.rect43StartTime;
                break;

            default:
                break;
        }
        if ( result === 'done' ) {
            this.submitAll();
        }
        if (cb) cb();
    }

    async submitAll() {
        try {
            this.submitting = true

            let blacklist : string[] = [];
            //if (this.circleResult  !== "cropped") blacklist.push("circle 1:1");
            //if (this.rect167Result !== "cropped") blacklist.push("rectangle 16:7");

            // upload image
            const formData = new FormData();
            formData.append('performerId', this.artistId)
            formData.append('uploadImage', this.uploadImage || this.uploadImage2!)
            if (this.image!.imageinfo.extendColor)
                formData.append('extendColor', this.image!.imageinfo.extendColor);
            if (blacklist)
                formData.append('cropShapeBlackList', JSON.stringify(blacklist));

            let data = {
                crops: [] as any[]
            };
            if (this.circleResult == "cropped" || this.circleResult === 'does_not_fit') {
                data.crops.push({
                    crop_shape:     'circle 1:1',
                    crop:           this.circleCrop,
                    duration:       this.circleDuration,
                    result:         this.circleResult
                });
            }
            if (this.rect167Result == "cropped" || this.rect167Result === 'does_not_fit') {
                data.crops.push({
                    crop_shape:     'rectangle 16:7',
                    crop:           this.rect167Crop,
                    duration:       this.rect167Duration,
                    result:         this.rect167Result
                });
            }
            if (this.rect43Result == "cropped" || this.rect43Result === 'does_not_fit') {
                data.crops.push({
                    crop_shape:     'rectangle 4:3',
                    crop:           this.rect43Crop,
                    duration:       this.rect43Duration,
                    result:         this.rect43Result
                });
            }

            formData.append('cropData', JSON.stringify(data));

            let res = await axios.post('/api/artdirector/1/upload', formData);
            this.$router.push("./");

        } catch (err) {
            console.log(err);
            this.$toasted.error('Something went wrong uploading image and crops. Please try again', { position: 'bottom-right', duration: 2000 })
        } finally {
            this.submitting = false;
        }
    }

    defaultCrop() : CropData {
        return {
            area: { u1: 0, v1: 0, u2: 0, v2: 0 },
            horizontallyFlipped: false,
        };
    }

    reset() {
        this.step             = 0;
        this.circleCrop       = this.defaultCrop();
        this.rect167Crop      = this.defaultCrop();
        this.rect43Crop      = this.defaultCrop();
    }
    created() {
        this.reset();
    }

    mounted() {
        window.addEventListener("paste", this.paste);
    }

    destroyed() {
      window.removeEventListener("paste", this.paste);
    }

    async triggerPaste(e: any) {
        if (navigator.clipboard) {
            //const items = await navigator.clipboard.read();   
            //items.forEach((i:any) => i.type = i.types)
            //this.paste({clipboardData: { items }})
        }
    }

    async paste(event: any) {
        /*
        console.log("pasted")
        console.log(event.clipboardData!.items);
        try { console.log("0:", event.clipboardData!.items[0]); } catch {}
        try { console.log("1:", event.clipboardData!.items[1]); } catch {}
        console.log(event.clipboardData!.getData("text"));
        */

        let items = (event.clipboardData || event.originalEvent.clipboardData).items;
        // find pasted image among pasted items
        let blob = null;
        for (var i = 0; i < items.length; i++) {
            if (items[i].type.indexOf("image") === 0) {
                blob = items[i].getAsFile();
            } 
            //else if (items[i].type.some((t: string) => /image*/.test(t))) {
            //blob = await items[i].getType(items[i].type.filter((t:string) => /image*/.test(t))[0]);
            //}
            /*
            if (items[i].type == "text/plain") {
                let url = event.clipboardData!.getData("text");
                if (url.indexOf("data:image/") === 0) {
                    let arr = url.split(','), mime = arr[0].match(/:(.*?);/)![1], bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
                    while (n--){ u8arr[n] = bstr.charCodeAt(n); }
                    this.uploadImage = null;
                    this.uploadImage2 =  new Blob([u8arr], {type:mime});
                    this.loadImageURL();
                    return;
                }
                if (/\.(png|jpg|jpeg)$/.test(url)) {
                    fetch(event.clipboardData!.getData("text")).then(function(response: any) {
                        return response.blob();
                    }).then((myBlob) => {
                        const objectURL = URL.createObjectURL(myBlob);
                        const img = new Image();
                        img.onload = (_: Event) => {
                            this.uploadImage = null;
                            this.uploadImage2 = myBlob;
                            this.loadImageURL();
                        };
                        img.src = objectURL;
                    });

                    return;
                }
                return;
            }
            */
        }

        // load image if there is a pasted image
        if (blob !== null) {
            let reader = new FileReader();
            let self = this;
            reader.onload = function(_: Event) {
                const dataurl = this.result as string;
                let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)![1], bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
                while (n--){ u8arr[n] = bstr.charCodeAt(n); }
                self.uploadImage = null;
                self.uploadImage2 =  new Blob([u8arr], {type:mime});
                self.loadImageURL();
            };
            reader.readAsDataURL(blob);
        }
    }
    handlePinchMove(s: number, startScale: string) {
       return (Math.max(0,Math.min(1,(parseFloat(startScale) + 1) * s - 1))).toString();
    }

    circleScaleStart  = '0';
    rect167ScaleStart = '0';
    rect43ScaleStart  = '0';
    pinch(c: string, s:any) {
        console.log(this.step, c, s)
        switch(this.step) {
            case 1:
                //avatar
                if ( c === 'start' ) {
                    this.circleScaleStart = this.circleScale;
                } else if ( c === 'move' ) {
                    this.circleScale = this.handlePinchMove(s, this.circleScaleStart); 
                }
                break;
            case 2:
                //banner
                if ( c === 'start' ) {
                    this.rect167ScaleStart = this.rect167Scale;
                } else if ( c === 'move' ) {
                    this.rect167Scale = this.handlePinchMove(s, this.rect167ScaleStart); 
                }
                break;
            case 3:
                //mobile
                if ( c === 'start' ) {
                    this.rect43ScaleStart = this.rect43Scale;
                } else if ( c === 'move' ) {
                    this.rect43Scale = this.handlePinchMove(s, this.rect43ScaleStart); 
                }
                break;
            default:
                break;
        }
    }
}
