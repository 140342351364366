

import axios                      from 'axios';
import { Vue, Component }         from 'vue-property-decorator'
import vep from 'vue-ellipse-progress';


import { Auth, refresh, signOut } from '@/core/auth';
import navbar                     from '@/components/NavBarData';
import { Lvl }                    from '@/components';

Vue.use(vep, "vep")

@Component({ components: { Lvl } })
export default class Profile extends Vue {
    stats : any | null = null;

    get user() {
        return Auth.user;
    }

    async created() {
        await refresh();
        this.stats = (await axios.get(`/api/artdirector/1/admin/profile-stats`)).data;
    }

    async signOut() {
        await signOut();
        this.$router.push('/info');
    }

    mounted() {
        navbar.searchTypes = [ ];
    }
}

