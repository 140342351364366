
import axios              from "axios";
import { Vue, Component } from 'vue-property-decorator';

import { Auth, refresh } from '@/core/auth';

@Component
export default class Leaderboard extends Vue {

    data:       any[]   = []
    total:      number  = 0;
    usersPage:  number  = 1;
    loading:    boolean = true;

    performer_user_map = {};

    get user() {
        return Auth.user;
    }

    onPageChange(page: number) {
        this.usersPage = page;
        this.getUsers();
    }

    goToUserWork(profile: any) {
        this.$router.push({ name: 'ArtDirectorWork',  params: { profileId: profile.profileId } })
    }
    async getUsers() {
        try {
            this.loading = true;
            const res    = await axios.get('/api/artdirector/1/admin/leaderboard', { params: { page: this.usersPage } });
            const json   = res.data;
            this.data    = json.users;
            this.total   = json.total;
            this.loading = false;
        } catch(err) {
            console.log(err);
        }
    }

    created() {
        this.getUsers();
    }
}
