
import { Vue, Component } from 'vue-property-decorator'
import { Auth }           from '@/core/auth';

// @ts-ignore
import RoonAuth from '@roonlabs/vue-roon-login';

@Component
export default class Info extends Vue {
    get user() { return Auth.user  }

    login() {
        RoonAuth.login();
    }
}
