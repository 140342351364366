

import { Vue, Component, Prop, Watch }  from 'vue-property-decorator'
import { RoonSearch, Spinner }          from '@/components';
import AdminImage                       from '../components/AdminImage.vue';

@Component({components: {RoonSearch, AdminImage}})
export default class MoveArtistWidget extends Vue {
    artist = null;
    reason = '';
    @Prop({}) name!: string; 
    @Prop({required: true}) image!: any; 
    @Prop({}) showBack!: boolean; 
    @Prop({}) showIdk!: boolean; 
    get reasonLabel() {
        return `Explain why this artist isn't ${this.name ? this.name : 'correct'}`
    }
}
