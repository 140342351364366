
import { Vue, Component }       from 'vue-property-decorator'

import { Born }                 from './types';

@Component({
    /*    timelineEditorInfo: {
        id: "born", 
        displayName: "Born",
        groupOrIndividual: "individual"
        }*/
})
export default class extends Vue {
}
