
import axios                           from 'axios';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

import { Auth }    from '@/core/auth';
import { Spinner } from '@/components'

import TimelineEditor                  from '../components/TimelineEditor.vue';

@Component({
    components: { Spinner, TimelineEditor }
})
export default class TimelineArtist extends Vue {
    @Prop({required: true}) artist!: string;

    isAddingEvent: boolean = false;
    initializing: boolean = false;

    get artistData() {
        return {
            artistId: this.artist
        };
    }

    @Watch('artist')
    onArtistChanged(newVal: string) {
        console.log('ARTIST CHANGED ', newVal);
        this.go();
    }

    created() { this.go(); }

    async go() {
        this.initializing = true;
        console.log("movement", "go-first", this.artist);
        this.initializing = false;
    }

    addEvent() {
        this.isAddingEvent = true;
    }
}
