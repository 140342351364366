import pageIndex   from "./views/index.vue"
import pageDefault from "./views/default.vue"
import pageArtist  from "./views/artist.vue"

const prefix = 'Timeline';

export default {
    path:      '/timelines',
    component: pageIndex,
    children: [
        {
            path:      '/',
            component: pageDefault,
            name:      prefix + 'Default'
        },
        {
            path:      '/timelines/artist/:artist',
            component: pageArtist,
            name:      prefix + 'Artist',
            props:     true,
        }
    ]
}
