
import axios                     from "axios";
import Cookies                   from 'js-cookie'

import { Vue, Component, Prop }  from 'vue-property-decorator'

import { Auth }                  from '@/core/auth';
import { Spinner }               from '@/components';

import type { Game }             from '../interfaces';
import { GameQueue }             from '../components/gamequeue';
import Crop                      from '../components/Crop.vue';
import Vote                      from '../components/Vote2.vue';

export class AutoQueue extends GameQueue {
    private _queue2 : AutoQueue | null = null;
    private _sentEnding       = false;
    private _allGames: Game[] = [];

    private _swapping = false;
    private _complete:   boolean = false;
    private _loading:    boolean = true;

    allGamesIndex = 0;
    source        = 'default';

    get totalGames() {
        return this._allGames.length;
    }

    get busy() {
        return this._swapping;
    }

    depth = 1;
    constructor(depth:number = 1) {
        super();
        this.depth = depth
    }

    async load(voteblacklist: string[] = [], cropblacklist: string[] = []) : Promise<void> {
        try {
            const body = {
                profileId:            Auth.user!.profileId,
                gameIdBlacklist:      cropblacklist,
                performerIdBlacklist: voteblacklist,
                games:           [],
                clumpProperties: {
                    numGames: 5
                }
            }

            const res  = await axios.post('/api/artdirector/1/next/', body)

            /*if (!res.ok) {
                this.$toasted.error('Oops something went wrong..', { position: 'bottom-right', duration: 2000 })
                return;
                }*/

            const json = res.data;
                    
            const gameTypeMap: Record<string, string> = {
                vote:      'Vote',
                crop:      'Crop',
            }

            for ( let i = 0; i < json.length; i++)
                json[i].type = gameTypeMap[json[i].gameType]

            this._allGames = json;
            this.games    = this._allGames.slice(0,2);
            this.index         = 0;
            this.allGamesIndex = 2;

            if (this.games.length <= 1 && this.depth < 3) {
                this._queue2 = this._queue2 || new AutoQueue(this.depth + 1);
                this.loadNextQueue()
            }

        } catch (e) {
            console.log(e)
            //this.$toasted.error('Oops something went wrong..', { position: 'bottom-right', duration: 2000 })
        } finally {
            this._loading = false;
        }
    }

    loadNextQueue() {
        if ( this._allGames[0].gameType === 'vote' ) {
            this._queue2!.load(this._allGames.map(g => g.performerId));
        } else {
            this._queue2!.load(undefined, this._allGames.map(g => g.gameId)); // run async in bg
        }
    }

    async next(result: any) : Promise<void> {
        let url, body;
        if ( result.gameType === 'flag' && result.original === 'crop' ) {
            //A crop is flagged
            body = {
                type:     result.type,
                uploadId: result.uploadId,
                cropId:   result.cropId,
                data:     result.data
            }
            axios.post('/api/artdirector/1/restricted/', body)
        } else if (result.gameType === 'crop') {
            //A crop is completed
            result.source = this.source;
            body = {
                profileId:       Auth.user!.profileId,
                gameIdBlacklist: this.games.map( ( g: any ) => g.gameId ),
                games:           [result],
                clumpProperties: { numGames: 5 },
            }
            axios.post('/api/artdirector/1/submit/', body)
        } else if ( result.gameType === 'vote' ) {
            //A vote is completed
            if ( this.games.length > 1 ) {
                for (let i = 0; i < 2; i++) {
                    if ( i === result.voted_for ) {
                        this.games[1].images[i] = this.games[0].images[i];
                        this.games[1].winner    = result.winner;
                    }
                }
            }
            body = {
                winner: result.winner,
                source: this.source
            }
            axios.post('/api/artdirector/1/vote/', body)
        } else if ( result.gameType === 'flag' && result.original === 'vote' ) {
            //A vote is flagged
            if ( this.games.length > 1 ) {
                for (let i = 0; i < 2; i++) {
                    if ( this.games[0].images[i].cropDetails.resultId !== result.cropId ) {
                        this.games[1].images[i] = this.games[0].images[i];
                        this.games[1].winner    = result.winner;
                    }
                }
            }
            body = {
                type:     result.type,
                uploadId: result.uploadId,
                cropId:   result.cropId,
                data:     result.data
            }
            axios.post('/api/artdirector/1/restricted/', body)
        } else {
            console.log('unknown game type, skipping post')
        }

        if ( this.games.length === 1 ) {
            if ( this._complete ) throw new Error('ALREADY COMPLETE');
            this._complete = true;
            await this.docomplete()
            return;
        }

        this.games.shift();
        while (this.games[0] && this.games[0].images[0].uploadId == result.uploadId && result.data && result.data.option === 'NOTARTIST') {
            this.games.shift();
        }
        while (this._allGames[this.allGamesIndex] && this._allGames[this.allGamesIndex].images[0].uploadId == result.uploadId && result.data && result.data.option === 'NOTARTIST') {
            this._allGames.splice(this.allGamesIndex,1);
        }

        if ( !this.games.length && !this._allGames[this.allGamesIndex] ) {
            await this.ending();
            await this.docomplete();
            return;
        }

        if ( this.index >= this.totalGames - 2) {
            this.index++;

            if (!this._sentEnding)
                this.ending(); // not async, just run in bg

            return;
        }

        this.games.push(this._allGames[this.allGamesIndex++]);
        this.index++;

        if ( this.index > this.totalGames - 6  && !this._sentEnding ) {
            this._sentEnding = true;
            this.ending();
            return;
        }
    }

    async ending() {
        this._queue2 = this._queue2 || new AutoQueue();
        this.loadNextQueue()
    }

    async docomplete() {
        this._swapping = true;
        this._queue2 = this._queue2 || new AutoQueue();

        while (this._queue2._loading) await new Promise(r => setTimeout(r, 200));

        this.games         = this._queue2.games;
        this.index         = this._queue2.index;
        this.allGamesIndex = this._queue2.allGamesIndex;
        this._sentEnding   = this._queue2._sentEnding;
        this._allGames     = this._queue2._allGames;
        this._complete     = this._queue2._complete;
        this._loading      = this._queue2._loading;

        this._swapping = false;
        this._queue2   = new AutoQueue();

        if ( this.games.length === 0 ) {
            //XXX no more games refresh to try again
        } else if ( this.games.length === 1 ) {
            this.loadNextQueue()
        }
    }
}

@Component({ components: { Spinner, Crop, Vote } })
export default class ArtistImages extends Vue {

    @Prop() playall?: GameQueue;

    queue:      GameQueue = new AutoQueue();
    swapping:   boolean = false;

    showModal:  boolean = false;

    $refs!: {
        body: HTMLDivElement;
    }

    get user() {
        return Auth.user!
    }

    get stepstyle() {
        return {
            width: `calc(100vw * ${(this.queue.index+1) / (this.queue.totalGames+1)}`,
            height: '4px',
        };
    }

    async handleCompleted(e: any) {
        this.queue.next(e.payload);
        this.$refs.body.scrollTop = 0;
    }

    async created() {
        if ( this.$route.params.firstRun && !Cookies.get("artdirector-firstrun") ) {
            this.showModal = true;
            Cookies.set("artdirector-firstrun", "true", { expires: 0.83, path: '' })
        }

       if ( this.playall ) {
           this.queue     = this.playall;
           this.queue.vue = this;
       } else {
           this.queue = new AutoQueue();
           await this.queue.load([]);
       }
    }
}
