import Vue                         from 'vue'
import VueRouter, { RouteConfig }  from 'vue-router'

import { Auth, refresh } from '@/core/auth';

import NotFound                    from '@/views/NotFound.vue';
import Info                        from '@/views/Info.vue';
import LicenseRequired             from '@/views/LicenseRequired.vue';

import Home                        from '@/views/Home.vue';
import Profile                     from '@/views/Profile.vue';
import Dashboard                   from '@/views/Dashboard.vue';

import artdirector                 from "@/artdirector/router"
import timelines                   from "@/timelines/router"
import axios                       from 'axios';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    { path: '/info',             name: 'Info',            component: Info, meta: { auth: false} },
    { path: '/license-required', name: 'LicenseRequired', component: LicenseRequired, meta: { auth: false} },
    { 
        path: '',         
        name: '',     
        component: Home,
        children: [
            {
                path: '/profile',
                component: Profile,
                name: 'Profile',
            },
            {
                path: '/',
                component: Dashboard,
                name: 'Dashboard',
                meta: {
                    authRedirect: '/info',
                }
            },
            artdirector,
            timelines,
        ]
    },
    { path: '*', name: '404', component: NotFound },
]

// @ts-ignore
import RoonAuth from '@roonlabs/vue-roon-login';

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

RoonAuth.install(router);

// Axios intercepter to show invalid license screen
axios.interceptors.response.use(
    response => response,
        error => {
        if (error.response.status === 403) {
            if (error.response.data?.status == 'NeedsPaidLicense') {
                router.push({ name: 'LicenseRequired' })
            }
        }
        return Promise.reject(error)
    }
)

router.beforeEach(async (to, from, next) => {
    if (to.name === 'LicenseRequired' || to.name === 'Info') {
        next();
        return;
    }
    if (!Auth.user) {
        console.log("ROUTER: no user, refreshing");
        await refresh();
    }
    next();
});

export default router
