
import axios                    from 'axios';
import { Vue, Component, Prop } from 'vue-property-decorator'


/**
    groups [
        {
            group:         string // name of group
            itemTitleProp: string // prop of list item to be displayed 
            items:         list(any) // list of data
        }
    ]
 **/

@Component
export default class RoonSearch extends Vue {

    @Prop({default:  ""                                   }) placeholder!: string
    @Prop({default:  200                                  }) delay!:       number
    @Prop({required: true                                 }) endpoint!:    string
    @Prop({default:  [ 'performers', 'albums', 'tracks' ] }) types!:       string[]
    @Prop({default:  5                                    }) limit!:       number
    @Prop({default:  false                                }) popupStyle!:  boolean

    canDrawerOpen: boolean    = false;
    query:         string     = '';
    searching:     boolean    = false;
    selected:      boolean    = false;
    searchSerial:  number     = 0
    isMouseDown:   boolean    = false;

    blur() {
        if ( this.isMouseDown ) return;
        this.closeDrawer();
        if (this.popupStyle && !this.selected) this.$emit('select', null);
    }

    mouseupdown(down: boolean) {
        this.isMouseDown = down
        if (!down) this.$refs.searchInput.focus();
    }

    get drawerOpen() {
        if (!this.canDrawerOpen) return false;
        if (this.searching) return true;
        if (this.query.trim().length) return true;
        if (this.recentSearchSize) return true;
        if (this.searchCount) return true;
        return false;

//        let hasnoresults = !this.searchCount && !this.searching && this.query.trim().length;
//        return !hasnoresults &&
//        return this.canDrawerOpen && (this.recentSearchSize + this.searchCount) ;
    }

    groups: any[]    = [{
        group:         'recentSearches',
        items: []
    }];

    activeGroupIndex:   number  = 0;
    activeIndex:        number  = 0; 

    $refs!: {
        searchInput: HTMLInputElement,
        top: HTMLElement
    }

    get active() {
        if ( this.activeGroupIndex === -1 ) return null;
        return this.groups[this.activeGroupIndex].items[this.activeIndex]
    }

    get recentSearchSize() {
        return this.groups[0].items.length;
    }

    get searchCount() {
        if ( this.groups.length === 1) return 0;
        return this.groups.slice(1).reduce((a: number, {items}: {items:any}) => a + items.length, 0)
    }

    openDrawer() {
        this.canDrawerOpen = true;
    }
    closeDrawer() {
        this.canDrawerOpen      = false;
        this.activeIndex        = -1;
        this.activeGroupIndex   = -1;
    }


    displayMap: Record<string, any> = {
        performers: (i: any) => {
            return i.performer.name
        },
        albums: (i: any) => {
            return [i.album.performedBy, i.album.title].join(' - ');
        },
        tracks: (i: any) => {
            return i.track.title
        },
        composers: (i: any) => {
            return i.performer.name
        }
    }
    idMap: Record<string, any> = {
        performers: (i: any) => {
            return i.performer.performerId
        },
        albums: (i: any) => {
            return i.album.albumId
        },
        tracks: (i: any) => {
            return i.track.trackId
        },
        composers: (i: any) => {
            return i.performer.performerId
        }
    }

    async search(query: string, serial: number) {
        if (serial != this.searchSerial) return;

        let q = query.trim();

        if (!q.length) {
            this.groups = [this.groups[0]]
            this.activeGroupIndex = -1;
            this.activeIndex      = -1;
            this.searching = false;
            return;
        }

        this.searching = true;
        try {
            const params = {
                q:     q,
                types: (this.types as string[]).map((x: string) => x.replace(/s$/, '')).join(','),
                count: this.limit.toString()
            };
            const searchRes = await axios.get(this.endpoint, { params });
            const json      = searchRes.data;

            if (serial != this.searchSerial) {
                this.searching = false;
                return;
            }

            let recents: Record<string, boolean> = { };
            for (let item of this.groups[0].items)
                recents[item.id] = true;
            // console.log(recents);

            let setIndexAlready = false;

            const groups = []
            for (let group of this.types as string[]) {
                let j = json[group];
                if(j && j.length) { 
                    let items = j.map( (i: any)  => { return {...i, display: this.displayMap[group as string](i), id: this.idMap[group as string](i) } });

                    let i = 0;
                    while (i < items.length) {
                        if (i == 0 && recents[items[0].id]) {
                            this.activeGroupIndex = 0;
                            let j = 0;
                            while (j < this.groups[0].items.length) {
                                if (this.groups[0].items[j].id == items[0].id) {
                                    this.activeIndex = j;
                                    break;
                                }
                                j++;
                            }
                            setIndexAlready = true;
                            items.splice(i,1);
                            continue;
                        }
                        if (recents[items[i].id])
                            items.splice(i,1);
                        else
                            i++;
                    }
                    groups.push({ group, items })
                }
            }
            this.groups = [this.groups[0], ...groups]

            if (!setIndexAlready) {
                if ( this.groups.length > 1 && this.groups[1].items.length ) {
                    this.activeGroupIndex = 1;
                    this.activeIndex      = 0;
                } else {
                    this.activeGroupIndex = -1;
                    this.activeIndex      = -1;
                }
            }
        } catch(err) {
            //emit error
            console.log(err)
        }
        this.searching = false
    }

    //Debounce search queries
    debounce() {
        this.searching = true;
        const go = () => { this.search(this.query, ++this.searchSerial); }

        let q = this.query.trim()
        if (!q.length) {
            go()
        } else {
            setTimeout(go, this.delay);
        }
    }

    select() {
        let item = this.active;

        //Save recent
        const recentIndex = this.groups[0].items.findIndex((e: any) => e.id === item.id)
        if (recentIndex != -1) this.groups[0].items.splice(recentIndex,1);
        this.groups[0].items.unshift(item);
        this.groups[0].items.splice(3); //limit to 3

        // console.log(item)

        this.query = item.display;

        // console.log("selecting", item.display)
        this.selected = true;
        this.$emit('select', item);

        this.$refs.searchInput.blur()
        this.closeDrawer();
    }

    decrementActive() {
        if ( this.activeGroupIndex === -1 ) {
            if ( this.recentSearchSize ) {
                this.activeGroupIndex = 0;
                this.activeIndex      = 0;
            }
            return;
        }
        if ( this.activeIndex === 0 ) {
            if ( this.activeGroupIndex === 0 ) return;
            if ( this.activeGroupIndex === 1 && !this.recentSearchSize ) return;
            this.activeGroupIndex -= 1;
            this.activeIndex = this.groups[this.activeGroupIndex].items.length - 1;
        } else {
            this.activeIndex--;
        }
    }

    incrementActive() {
        if ( this.activeGroupIndex === -1 ) {
            if ( this.recentSearchSize ) {
                this.activeGroupIndex = 0;
                this.activeIndex      = 0;
            }
            return;
        }

        if ( this.activeIndex === this.groups[this.activeGroupIndex].items.length - 1 ) {
            if ( this.activeGroupIndex === this.groups.length - 1 ) return;
            this.activeGroupIndex += 1;
            this.activeIndex = 0; 
        } else {
            this.activeIndex++;
        }
    }

    keyboard(e: KeyboardEvent) {
        e.stopPropagation();

        // console.log(e)

        //esc closes search
        if ( e.which === 27 ) {
            this.$refs.searchInput.blur()
            this.closeDrawer()
            e.preventDefault();
        }

        if (e.which === 8 && this.popupStyle && this.query.length == 0) {
            this.$refs.searchInput.blur()
            this.closeDrawer()
            e.preventDefault();
        }

        if (!this.groups || !this.drawerOpen) return;
        
        switch(e.which) {
            case 38: // up arrow
                if (this.drawerOpen) e.preventDefault();
                this.decrementActive()
                break;

            case 40: // down arrow
                if (this.drawerOpen) e.preventDefault();
                this.incrementActive()
                break;

            case 13: //enter
                e.preventDefault();
                if (this.active) {
                    this.select();
                } else if (!this.query) {
                    this.$refs.searchInput.blur(); 
                } else {
                    // nothing
                }
                break;
        }
    }

    outsideClick(e: MouseEvent | TouchEvent) {
        this.closeDrawer();
    }

    mounted() {
        //document.body.addEventListener('click', this.outsideClick); 
    }

    beforeDestroy() {
        //document.body.removeEventListener('click', this.outsideClick)
    }

    setLocation(x: string, y: string) {
        this.$refs.top.style.position = "fixed"
        this.$refs.top.style.left = x
        this.$refs.top.style.top = y
        
    }

    focus() {
        setTimeout(() => { this.$refs.searchInput.focus() });
    }

    setQuery(payload: any | null) {
        this.selected = false
        if (!payload) {
            this.query = "";
            this.search(this.query, ++this.searchSerial);
        }
    }

}
