import { Vue, Component } from 'vue-property-decorator'

@Component
class NavBarData extends Vue {
    showHelp: boolean = false;

    searchTypes: string[] = [ 'performers' ]

    handleHelp() {
        this.$emit('help')
    }
    handleSearch(payload: any) {
        this.$emit('searched', payload)
    }
}

export default new NavBarData();
