

import { Vue, Component }  from 'vue-property-decorator'

import { Auth }            from '@/core/auth';
import navbar              from '@/components/NavBarData';
import { RoonSearch, Lvl } from '@/components';

// @ts-ignore
import RoonAuth from '@roonlabs/vue-roon-login';

@Component({components: { RoonSearch, Lvl } })
export default class Home extends Vue {
    get navbar()      { return navbar; }
    get user()        { return Auth.user; }
    get messages()    { return Auth.messages; }
    get numMessages() { return Auth.numMessages; }

    $refs!: {
        mobilesearchmenu: any // we don't have a type for a buefy b-dropdown :-(
    }

    handleSearch(payload: any) {
        navbar.handleSearch(payload)
        this.$refs.mobilesearchmenu.toggle()
    }

    showHelp() {
        navbar.handleHelp()
    }

    login() {
        RoonAuth.login();
    }

    clickTier(e: Event) {
        if (Auth.user!.role == "admin") {
            e.preventDefault();
            e.stopPropagation();

            let n = Auth.user!.tier.number;
            if (n >= 3)
                n = 1;
            else
                n++;

            Auth.user!.tier.number = n;
        }
    }
}
