import type { Game }      from '../interfaces';

export abstract class GameQueue {
    games:      Game[]  = [];
    index:      number  = 0;
    vue?:       any     = null;
    source:     string  = '';

    abstract load(blackList: string[]) : Promise<void>;
    abstract next(result: any) : Promise<void>;
    abstract get totalGames() : number;
    abstract get busy() : boolean;
}
