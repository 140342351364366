
import { Vue, Component, Prop, Model, PropSync } from 'vue-property-decorator'

import type { Crop }                   from "../interfaces"

@Component
export default class CircleCrop extends Vue {
    @Model('crop') crop?: Crop
    @Prop({default: false}) readonly!: boolean;
    @Prop({default: '200px'}) size!: string;
    @PropSync('scale') syncedScale!: string;

    $slots!: {
        default: any;
    }
    
    zoom(direction: string) {
        this.$slots.default[0].componentInstance.zoom(direction);
    }
    flip() {
        this.$slots.default[0].componentInstance.flip();
    }
    reset() {
        this.$slots.default[0].componentInstance.reset();
    }


    circleMaskStyle(x: any, y: any) {
        return {
            position:     'absolute',
            top:          `calc((${y} * var(--circlesz) * 1.2))`,
            left:         `calc(50% + ${x} * var(--circlesz) * 1.1)`,
            transform:    `translateX(-50%)`,
            borderRadius: '50%',
        }
    }
}
