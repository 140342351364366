import axios         from "axios";

import { GameQueue } from '../components/gamequeue';
import { Game }      from '../interfaces';

export class CropAllQueue extends GameQueue {
    allGames: any[]   = [];
    returnBag: any[]  = [];
    returnWithCb      = false;

    completeCallback: () => any;
    submitting: number     = 0;
    private _complete      = false;
    private _allGamesIndex = 0;

    constructor(games: any[], source: string, completeCallback: (...args: any[]) => any, returnWithCb: boolean = false) {
        super();
        this.allGames       = games;
        this._allGamesIndex = 0;
        this.index          = 0;
        this.source         = source;
        this.returnWithCb   = returnWithCb;

        if ( this.allGames.length > 1 ) {
            this.games.push(this.allGames[0])
            this.games.push(this.allGames[1])
        } else {
            this.games.push(this.allGames[0])
            this.games.push({gameId: 'DUMMMY'} as Game)
        }
        this._allGamesIndex   = 1;
        this.completeCallback = () => {
            if ( this.returnWithCb ) {
                completeCallback(this.returnBag);
            } else {
                completeCallback();
            }
        }
    }

    get totalGames() {
        return this.allGames.length;
    }

    get busy() { return false; };

    async load(blackList: string[]) : Promise<void> {}

    async submitResult(result: any) {
        if (this.returnWithCb) {
            this.returnBag.push(result);
            return;
        }
        result.gameId = JSON.parse(this.games[0].gameId);
        if ( result.gameType !== 'flag' ) {
            result.source = this.source;
        }
        this.submitting++;
        const url     = result.gameType === 'flag' ? '/api/artdirector/1/restricted' : '/api/artdirector/1/submit';
        const body    = result.gameType === 'flag' ? result : {
            gameIdBlacklist: [],
            games:           [result],
            clumpProperties: { }
        }
        axios.post(url, body)
        .then(() => {
            this.submitting--;
            if ( this._complete ) {
                const that = this;
                ( async function() {
                    while ( that.submitting !== 0 ) await new Promise(r => setTimeout(r, 200)); 
                    console.log('waiting...')
                    if ( that.completeCallback ) that.completeCallback();
                 }
                )()
            }
        })
        .catch((e: any) => {
            this.submitting--;
            console.log(e);
        })
    }
    async next(result: any) : Promise<void> {
        this.submitResult(result);

        if ( this.index > this.allGames.length - 2) {
            this._complete = true;
            this.games     = [{} as Game, {} as Game];
            if ( this.returnWithCb ) {
                this.completeCallback();
            }
            return;
        }

        this.games.shift();
        while (this.games[0] && this.games[0].images[0].uploadId == result.uploadId && result.data && result.data.option === 'NOTARTIST') {
            this.games.shift();
        }
        while (this.allGames[this._allGamesIndex] && this.allGames[this._allGamesIndex].images[0].uploadId == result.uploadId && result.data && result.data.option === 'NOTARTIST') {
            this.allGames.splice(this._allGamesIndex,1);
        }
        if ( !this.games.length && !this.allGames[this._allGamesIndex] ) {
            while (!!this.submitting) await new Promise(r => setTimeout(r, 200));
            console.log('it worked!!!')
            this.completeCallback();
            return;
        }

        if ( this.index === this.allGames.length - 2) {
            this.index++;
            this._allGamesIndex++;
            this.games.push({ gameId: 'DUMMY' } as Game)
        } else {
            this.index++;
            this.games.push(this.allGames[++this._allGamesIndex]);
        }
        if ( ( this.index === 5 || ( this.index - 5 ) % 10 === 0 ) && this.index !== this.allGames.length - 2 ) {
            if ( this.vue ) {
                this.vue.$buefy.dialog.confirm({
                    type: "is-outlined",
                    message: "You've adjusted several images. You can either rank the images already adjusted, or continue adjusting more.",
                    confirmText: 'Continue Adjusting',
                    cancelText:  'Rank',
                    onCancel: this.completeCallback
                })
            }
        }
    }
}

export class VoteAllQueue extends GameQueue {
    crops:      any[]  = [];
    submitting: number = 0;

    completeCallback: () => any;

    private _complete   = false;
    private _shape      = '';
    private _totalGames = 0;

    nextImage: HTMLImageElement | null = null;

    constructor(crops: any[], source: string, completeCallback: () => any) {
        super();
        if ( crops.length < 2 ) throw new Error('Less than 2 crops provided');
        this.crops       = crops;
        this.index       = 0;
        this._shape      = this.crops[0].shape;
        this._totalGames = this.crops.length - 1;
        this.source      = source;

        const game1 = this.createGame(
            this.crops.shift(), 
            this.crops.shift()
        );
        this.games            = [game1, { gameId: 'DUMMY' } as Game]
        this.nextImage        = this.loadNextImage();
        this.completeCallback = completeCallback;
    }

    loadNextImage() {
        if ( this.crops.length ) {
            const img = new Image();
            img.src = this.crops[0].url;
            return img;
        }
        return null;
    }

    get dummyCrop() {
        return {
            dummy:     true,
            shape:     this._shape,
            imageinfo: {}
        }
    }

    get totalGames() {
        return this._totalGames; 
    }

    get busy() { return false; };

    async load(blackList: string[]) : Promise<void> {};

    async submitResult(result: any) {
        this.submitting++;
        const url = result.gameType === 'flag' ? '/api/artdirector/1/restricted' : '/api/artdirector/1/vote';
        if ( result.gameType !== 'flag' ) {
            result.source = this.source;
        }
        axios.post(url, result)
        .then(() => {
            this.submitting--;
            if ( this._complete ) {
                const that = this;
                ( async function() {
                    while ( that.submitting !== 0 ) await new Promise(r => setTimeout(r, 200)); 
                    console.log('waiting...')
                    if ( that.completeCallback ) that.completeCallback();
                 }
                )()
            }
        })
        .catch((e: any) => {
            console.log(e);
            this.submitting--;
        })
    }

    async next(result: any) : Promise<void> {
        this.submitResult(result);

        let cropToSave;
        if ( result.gameType == 'flag' ) {
            const ids     = this.games[0].images.map((i:any) => i.cropDetails.resultId);
            cropToSave    = ids.filter((i:number) => i !== result.flaggedImage.cropDetails.resultId)[0];
        } else {
            cropToSave           = result.winner;
            this.games[0].winner = cropToSave;
        }

        if ( this.index === this.totalGames - 1 ) {
            console.log('Submitted last game')
            this._complete = true;
            return;
        }

        for ( let i = 0; i < 2; i++ ) {
            if ( this.games[0].images[i].cropDetails.resultId !== cropToSave ) {
                const g2 = this.createGame(this.crops.shift(), this.dummyCrop);
                this.games[0].images[i] = g2.images[0];
                this.nextImage          = this.loadNextImage();
                break;
            }
        }
        this.index++;
    }

    createGame(crop1: any, crop2: any): Game  {
         return {
             winner: crop1.winner ? crop1.key : crop2.winner ? crop2.key : null,
             gameId: new Date().toISOString(),
             gameType:        'vote',
             type:            'Vote',
             performerId:     crop1.performerId     || crop2.performerId,
             performerGenres: crop1.performerGenres || crop2.performerGenres,
             performerName:   crop1.performerName   || crop2.performerName,
             images: [
                 {
                     imageinfo:   crop1.imageinfo,
                     gameId:      crop1.gameId,
                     url:         crop1.url,
                     uploadId:    crop1.uploadId,
                     cropDetails: {
                         crop:          crop1.crop,
                         resultId:      crop1.key,
                         crop_shape:    crop1.shape,
                     },
                     dummy: crop1.dummy
                 },
                 {
                     imageinfo:   crop2.imageinfo,
                     gameId:      crop2.gameId,
                     url:         crop2.url,
                     uploadId:    crop2.uploadId,
                     cropDetails: {
                         crop:          crop2.crop,
                         resultId:      crop2.key,
                         crop_shape:    crop2.shape, 
                     },
                     dummy: crop2.dummy
                 }
             ]
         }
    }
}
