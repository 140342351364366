
import { Vue, Component, Mixins } from 'vue-property-decorator'

import { Spinner }                from '@/components';

import { CropData }               from '../interfaces';
import GameMixin                  from './GameMixin';
import Croppable                  from './Croppable.vue';
import CircleCrop                 from './CircleCrop.vue';
import Rect167Crop                from './Rect167Crop.vue';
import FourByThreeCrop            from './FourByThreeCrop.vue';
import MoveArtistWidget            from './MoveArtistWidget.vue';

@Component({
    components: { Rect167Crop, CircleCrop, FourByThreeCrop, Croppable, Spinner }
})
export default class Crop extends Mixins(GameMixin) {
    submitFlag(option: string, reason?: string) {
        const options: Record<string, string> = {
            'NOTARTIST':     'This flagging came from the main flow.',
            'INAPPROPRIATE': 'This image is offensive or otherwise inappropriate',
        }
        if (!options[option]) throw new Error();
        const body = {
            original:      'crop',
            gameType:      'flag',
            type:          'flag',
            uploadId:      this.game.images[0].uploadId,
            cropId:        null,
            data: {
                option:        option,
                reason:        reason ? reason : options[option] 
            }
        }
        this.submit('report', body);
    }

    itemsToLoad = 1;


    startScale = '';
    pinch(c: string, s: number) {
        switch (c) {
            case 'start':
                this.startScale = this.scale;
                break;
            case 'move':
                this.scale = (Math.max(0,Math.min(1,(parseFloat(this.startScale) + 1) * s - 1))).toString();
                console.log(this, this.scale)
                break;
            case 'stop':
                break;
        }
    }

    scale = "0";
    crop: CropData = {
        area:                {u1: 0, v1: 0, u2: 0, v2: 0 },
        horizontallyFlipped: false,
    }

    $refs!: {
        cropper: Croppable,
        cropviewport: HTMLDivElement
    }

    get shape() {
        return this.game.images[0].cropDetails.crop_shape;
    }

    go(status: string) {
        if ( status === 'cropped' && ( this.crop.area.u1 < 0 || this.crop.area.u2 < 0 || this.crop.area.v1 < 0 || this.crop.area.v2 < 0 ) ) {
            //Alert to user they have zoomed out past edge of image
            const { result } = this.$buefy.dialog.confirm({
                type: "is-outlined",
                message: "You've zoomed outisde the bounds of the original image. This is most likely not what you intended, unless the image is a logo or the background seemlessly blends into a solid color.",
                confirmText: "Submit anyway",
                cancelText:  "Let me try again",
                onConfirm: () => {
                    this.submit(status, {
                        gameType:       this.game.gameType,
                        gameId:         this.game.gameId,
                        crop:           this.crop,
                        duration:       new Date().getTime() - this.startTime,
                        result:         status
                    })
                },
                onCancel: () => {}
            })
        } else {
            this.submit(status, {
                gameType:       this.game.gameType,
                gameId:         this.game.gameId,
                crop:           this.crop,
                duration:       new Date().getTime() - this.startTime,
                result:         status
            })
        }
    }

    start() {
        this.$refs.cropper.start()
        this.startTime = new Date().getTime();
        //XXX Need to do a set timeout here if image is not loaded yet
//        console.log(new Date());
//        console.log('starting...', this.game.images[0].url.replace(/^.*[\\\/]/, ''));
    }

    /*
    //Mobile touch zoom
    pinchDistance      = 0;
    pinching           = false;
    startPinchDistance = 0;
    startScale         = '0'
    pinchstart(e: any) {
        if ( e.touches.length === 2 ) {
            e.preventDefault()
            this.pinching = true
            this.pinchDistance = Math.hypot(
                e.touches[0].pageX - e.touches[1].pageX,
                e.touches[0].pageY - e.touches[1].pageY,
            )
            this.startPinchDistance = this.pinchDistance;
            this.startScale         = this.scale;
            document.ontouchmove    = this.pinchmove;
            document.ontouchend     = this.pinchend;
        }
    }
    pinchmove(e: any) {
        if ( e.touches.length < 2 ) return;
        e.preventDefault();
        e.stopImmediatePropagation();
        const newDist = Math.hypot(
            e.touches[0].pageX - e.touches[1].pageX,
            e.touches[0].pageY - e.touches[1].pageY,
        )

        let s = newDist / this.startPinchDistance;
        s -= 1
        s *= 0.6 //speed damper
        s += 1

        this.scale = (Math.min(1,(parseFloat(this.startScale) + 1) * s - 1)).toString();

        return;
        if ( newDist > this.pinchDistance ) {
            this.$refs.cropper.zoom('in', 2 * newDist / this.pinchDistance )
            this.pinchDistance = newDist;
        } else if ( newDist < this.pinchDistance ) {
            this.$refs.cropper.zoom('out', 2 * newDist / this.pinchDistance )
            this.pinchDistance = newDist;
        }
    }

    pinchend(e: any) {
        if ( e.touches.length < 2 ) return;
        e.preventDefault();
        e.stopImmediatePropagation();
        this.pinching      = false;
        this.pinchDistance = 0;
    }

     */

}
