
import { Vue, Component } from 'vue-property-decorator'

// @ts-ignore
import RoonAuth           from '@roonlabs/vue-roon-login'

@Component({})
export default class LicenseRequired extends Vue 
{
    login() {
        RoonAuth.login();
    }
}
