
import axios                          from 'axios';
import { Vue, Component, Watch }      from 'vue-property-decorator'

import { Auth }                       from '@/core/auth';
import navbar                         from '@/components/NavBarData';
// import { Spinner }                    from '@/components';

@Component({
    // components: { Spinner },
    // filters: {
    //     formatNumber: function(value: any) {
    //         if ( value < 1000 ) return value;
    //         const map = [
    //             {val: 1E3, a: 'K'},
    //             {val: 1E6, a: 'M'},
    //             {val: 1E9, a: 'B'},
    //         ]
    //         let i;
    //         for ( i = 0; i < map.length - 1; i++) {
    //             if (map[i].val < value) break;
    //         }
    //         return ( value / map[i].val ).toFixed(2).replace('.00', '') + map[i].a
    //     }
    // }
})
export default class Dashboard extends Vue {
    // loading: boolean = true;

    // adminStats: {
    //     gamesPlayed:           number;
    //     gamesPlayedToday:      number;
    //     gamesPlayedThisWeek:   number;
    //     gamesPlayedThisMonth:  number;
    //     votes:        number;
    //     voted:        number;
    //     badVotes:     number;
    //     skippedVotes: number;
    //     crops:        number;
    //     cropped:      number;
    //     doesNotFit:   number;
    //     skippedCrops: number;
    // } = {
    //     gamesPlayed:           0,
    //     gamesPlayedToday:      0,
    //     gamesPlayedThisWeek:   0,
    //     gamesPlayedThisMonth:  0,
    //     votes:         0,
    //     voted:         0,
    //     badVotes:      0,
    //     skippedVotes:  0,
    //     crops:         0,
    //     cropped:       0,
    //     doesNotFit:    0,
    //     skippedCrops:  0,
    // }

    get user() {
        return Auth.user || {};
    }

    mounted() {
        navbar.searchTypes = [ ];
    }

    // async getImagesStats() {
    //     try {
    //         const res       = await axios.get(`/api/artdirector/1/admin/stats`);
    //         const json      = res.data;
    //         this.adminStats = { ...json }
    //     } catch (err) {
    //         console.log(err);
    //         //XXX
    //     }
    //     this.loading = false;
    // }
    //
    // @Watch("user")
    // onUserChanged() {
    //     this.getImagesStats();
    // }
    //
    // async created() {
    //     if (Auth.user)
    //         if ( Auth.user.role == "admin" ) await this.getImagesStats();
    // }
}

