
import { Vue, Component } from 'vue-property-decorator'
import { Auth }           from '@/core/auth';
import { Spinner }        from '@/components';
import navbar             from '@/components/NavBarData';

@Component({components: { Spinner } })
export default class Timeline extends Vue {
    handleSearch(payload: any) {
        if ( payload.performer ) {
            this.$router.push({ name: 'TimelineArtist', params: { artist: payload.performer.roonPerformerId }})
        }
        return;
    }

    mounted() {
        navbar.searchTypes = [ 'performers' ];
        navbar.$on('searched', this.handleSearch);
    }
    destroyed() {
        navbar.$off('searched', this.handleSearch);
    }
}

