
import { Vue, Component }  from 'vue-property-decorator'

@Component
export default class Manual extends Vue {
    isDialogShown: boolean = false

    hide() { this.isDialogShown = false; }
    show() { this.isDialogShown = true;  }
}

