
import { Vue, Component, Model, Prop, PropSync } from 'vue-property-decorator'

import type { Crop }             from "../interfaces"

@Component
export default class Rect167Crop extends Vue {
    @Model('crop') crop?: Crop
    @Prop({default: false}) readonly!: boolean;
    @PropSync('scale') syncedScale!: string;

    $slots!: {
        default: any;
    }

    zoom(direction: string) {
        this.$slots.default[0].componentInstance.zoom(direction);
    }
    flip() {
        this.$slots.default[0].componentInstance.flip();
    }
    reset() {
        this.$slots.default[0].componentInstance.reset();
    }
}
