export const VuePinch = {
    install(Vue: any) {
        Vue.directive('pinch', {
            bind: (el: any, binding: any) =>  {
                el.__pinchstate = {
                    startPinchDistance : 0,
                }

                el.pinchmove = function(e:any) {
                    if ( e.touches.length < 2 ) return;
                    e.preventDefault();
                    e.stopImmediatePropagation();
                    const newDist = Math.hypot(
                        e.touches[0].pageX - e.touches[1].pageX,
                        e.touches[0].pageY - e.touches[1].pageY,
                    )
                    
                    let s = newDist / el.__pinchstate.startPinchDistance;
                    s -= 1
                    s *= 0.6 //speed damper
                    s += 1
                    binding.value('move',s)
                }

                el.pinchend  = function(e:any) {
                    if ( e.touches.length < 2 ) return;
                    e.preventDefault();
                    e.stopImmediatePropagation();
                    document.ontouchmove            = null;
                    document.ontouchend             = null;
                    binding.value('stop',0)
                }

                el.pinchstart = function(e:any) {
                    if ( e.touches.length === 2 ) {
                        e.preventDefault()
                        el.__pinchstate.startPinchDistance = Math.hypot(
                            e.touches[0].pageX - e.touches[1].pageX,
                            e.touches[0].pageY - e.touches[1].pageY,
                        )
                        binding.value('start',0)
                        document.ontouchmove    = el.pinchmove;
                        document.ontouchend     = el.pinchend;
                    }
                }
                el.ontouchstart = el.pinchstart;
            }
        })
    }
};
