
import axios             from "axios";
import dayjs             from "dayjs";
import relativeTime      from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)


import { Vue, Component } from 'vue-property-decorator';

import { Spinner, RoonSearch }          from '@/components';
import { Auth }                         from '@/core/auth';

import Rect167Crop                      from '../components/Rect167Crop.vue';
import Croppable                        from '../components/Croppable.vue';
import AdminImage                       from '../components/AdminImage.vue';


@Component({ components: { RoonSearch, Croppable, Rect167Crop, AdminImage } })
export default class Moderate extends Vue {

    get isAdmin() {
        return Auth.user!.role === 'admin';
    }

    get user() {
        return Auth.user;
    }

    shapeStyle(shape: string) {
        switch (shape) {
            case 'circle 1:1':
                return { height: '150px', width: '150px' }
            case 'rectangle 4:3':
                return { height: '150px', width: '200px' }
            case 'rectangle 16:7':
                return { height: '131.25px', width: '300px' }
        }
    }

    openArtistPage(performerId: string, tab: string) {
        let route = this.$router.resolve({path: `/artdirector/artist/${performerId}`});
        window.open(route.href + '?tab=' + tab, '_blank');
    }

    get messages() {
        return Auth.messages.map((m:any) => {
            return Object.assign({}, m, {
                undoCircle:    true,
                undoRect43:    true,
                undoRect167:   true,
                uploadCircle:  'crop_keep',
                uploadRect43:  'crop_keep',
                uploadRect167: 'crop_keep',
            })
        });
    }

    get uploads() {
        return this.messages.filter((m:any) => m.type === 'upload')
    }

    showGuidelines           = false;
    showGalleryModal         = false;
    showModal                = false;
    modalMessage: any | null = null
    artistImages             = [];
    searchArtist             = '';

    openArtistGallery(performerId: string) {
        this.getArtistImages(performerId);
        this.showGalleryModal = true;
    }

    openModal(m: any) {
        this.modalMessage = m;
        this.showModal    = true;
        Auth.setViewing(m.id, true)
    }
    closeModal() {
        Auth.setViewing(this.modalMessage.id, false)
        this.showModal       = false
        this.modalMessage    = null;
        this.artistImages    = [];
        this.searchArtist    = '';
    }

    /**
    handleSearch(payload: any) {
        console.log(payload);
        this.movePerformerId = payload.id;
        this.searchArtist    = payload.performer.name;
    }
     **/

    async getArtistImages(performerId: string) {
        try {
            const res         = await axios.get(`/api/artdirector/1/artists/${performerId}/images`)
            const json        = res.data;
            this.artistImages = json.images;
        } catch (err) {
            console.log(err)
        }
    }

    uploadImageChoice = ''
    adjustment1Choice = ''
    adjustment2Choice = ''
    get canModerateUpload() {
        return !this.uploadImageChoice || (this.uploadImageChoice === 'image_keep' && ( !this.adjustment1Choice ||  !this.adjustment2Choice) )
    }

    dayjs(d: Date) {
        return dayjs(d);
    }

    async moveImage(id: string) {
        try {
            const body = {id};
            const res  = await axios.post('/api/artdirector/1/restricted/move-image', body)
        } catch (err) {
            console.log(err);
        }
        //this.closeModal();
    }

    async undoWrongArtist(message: any) {
        try {
            const shapes: string[]  = []; 
            if ( message.undoCircle ) shapes.push('circle 1:1')
            if ( message.undoRect43 ) shapes.push('rectangle 4:3')
            if ( message.undoRect167) shapes.push('rectangle 16:7')
            const body   = {id:message.id, shapes};
            const res    = await axios.post('/api/artdirector/1/restricted/keep-artist', body)
        } catch (err) {
            console.log(err);
        }
        //this.closeModal();
    }

    async deleteImage(id: string) {
        try {
            const body = {id};
            const res  = await axios.post('/api/artdirector/1/restricted/delete-image', body)
        } catch (err) {
            console.log(err);
        }
        //this.closeModal()
    }

    async approveAsLogo(message: any) {
        const body = { 
            id: message.id, 
            moveTo: null, 
            shapes: {
                'circle 1:1':     'crop_bad',
                'rectangle 16:7': 'crop_doesnotfit', 
                'rectangle 4:3':  'crop_doesnotfit' 
            }
        } as any;

        if ( message.uploadCircle ) body.shapes['circle 1:1']     = 'crop_keep';

        try {
            const res  = await axios.post('/api/artdirector/1/restricted/approve-upload', body)
        } catch (err) {
            console.log(err);
        }
    }

    async approveUpload(message: any) {
        const body = { 
            id: message.id, 
            moveTo: null, 
            shapes: {
                'circle 1:1':     message.uploadCircle,
                'rectangle 16:7': message.uploadRect167, 
                'rectangle 4:3':  message.uploadRect43 
            }
        } as any;

        try {
            const res  = await axios.post('/api/artdirector/1/restricted/approve-upload', body)
        } catch (err) {
            console.log(err);
        }
    }

    async approveAdjustment(id: string) {
        try {
            const body = {id};
            const res  = await axios.post('/api/artdirector/1/restricted/approve-adjustment', body)
        } catch (err) {
            console.log(err);
        }
    }

    async keepNoArtist(id: string) {
        try {
            const body = { id };
            const res  = await axios.post('/api/artdirector/1/restricted/keep-noartist', body)
        } catch (err) {
            console.log(err)
        }
    }

    async resolve(id: string, option: string) {
        const map: Record<string, boolean> = {
            'delete-adjustment': false,
            'deny-upload':       false,
            'ignore':            true,
        }

        const keep = map[option];
        if (keep === undefined) throw new Error('bad option ' + option);

        try {
            const body = { id, keep };
            const res  = await axios.post('/api/artdirector/1/restricted/resolve', body)
        } catch (err) {
        }
        //this.closeModal()
    }

}

