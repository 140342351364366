
import axios              from "axios";
import dayjs              from "dayjs";

import { Vue, Component, Prop, Watch }  from 'vue-property-decorator'

import { RoonSearch, Spinner }          from '@/components';
import { Auth }                         from '@/core/auth';

import { CropAllQueue, VoteAllQueue }   from '../components/playall';

import AdminImage                       from '../components/AdminImage.vue';
import CircleCrop                       from '../components/CircleCrop.vue';
import Rect167Crop                      from '../components/Rect167Crop.vue';
import FourByThreeCrop                  from '../components/FourByThreeCrop.vue';
import Croppable                        from '../components/Croppable.vue';
import MoveArtistWidget                 from '../components/MoveArtistWidget.vue';


@Component({components: { RoonSearch, Spinner, AdminImage, CircleCrop, Rect167Crop, FourByThreeCrop, Croppable, MoveArtistWidget } })
export default class Artist extends Vue {

    @Prop({required: true}) artistId!: string
    @Prop({default: null})  uploadId!: string | null
    @Prop({default: null})  tab!:      string | null

    @Watch("artistId")
    onArtistIdChanged() {
        this.reload()
    }

    activeTab : string = "unadjusted"

    dayjs(d: Date) {
        return dayjs(d);
    }

    mounted() {
        this.reload();
    }

    get user() {
        return Auth.user;
    }

    mywinners:  Record<string, number> = {}
    rankings:   Record<string, any>    = {}

    allImages:       any[] = [];
    loading:         boolean = true;


    wrongArtist: any | null = null;
    
    async getWrongArtist(artist: any) {
        try {
            this.wrongArtist        = {...artist, loading: true};
            const res               = await axios.get(`/api/artdirector/1/artists/${artist.id}/images`)
            const json              = res.data;
            this.wrongArtist        = {...artist, loading: false, images: json.images };
        } catch (err) {
            console.log(err)
        }
    }

    artistData: {
        artistName:         string,
        artistId:           string,
        artistGenres:       string[],
        artistType:         string | null,
        systemImages:       number,
        userProvidedImages: number,
    } = {
        artistName:      '',
        artistId:        '',
        artistGenres:       [],
        artistType:         null,
        systemImages:       0,
        userProvidedImages: 0,
    }

    reload(pTab?: string) {
        this._all         = null;
        this._active      = null;
        this._nonCrops    = null;
        this._circles     = null; 
        this._rects       = null;
        this._mobile      = null;
        this._wrongArtist = null;
        this.allImages = [];
        this.artistData   = {
            artistName:      '',
            artistId:        '',
            artistGenres:       [],
            artistType:         null,
            systemImages:       0,
            userProvidedImages: 0,
        }
        this.getArtistInfo(this.artistId, pTab);
   }


    openArtistPage(performerId: string, tab: string) {
        let route = this.$router.resolve({path: `/artdirector/artist/${performerId}`});
        window.open(route.href + '?tab=' + tab, '_blank');
    }

    open(gameId: string) {
        let route = this.$router.resolve({path: `/artdirector/${gameId}`});
        window.open(route.href, '_blank');
    }
    
    imageCanBeAdjusted(image: any, shape: any): boolean {
        const flagMap: Record<string,string> = { 'circle 1:1': 'flaggedCircle', 'rectangle 4:3': 'flaggedRect43', 'rectangle 16:7': 'flaggedRect167' }
        const shapeFilter = (shape: string, image: any) => {
                return (
                    !image[flagMap[shape]] &&
                    image.cropShapeBlacklist.every((c:string) => c !== shape) && 
                    (image.crops || []).every( ( crop: any ) => (crop.shape != shape) )
                )
        }
        switch ( shape ) {
            case 'circle 1:1':
                return shapeFilter(shape, image)
            case 'rectangle 4:3':
                return shapeFilter(shape, image)
            case 'rectangle 16:7':
                return shapeFilter(shape, image)
            case 'all':
                return this.imageCanBeAdjusted(image, 'circle 1:1') || this.imageCanBeAdjusted(image,'rectangle 4:3') || this.imageCanBeAdjusted(image, 'rectangle 16:7')
            default:
                throw new Error()

        }
    }

    _cropImage(image: any, shape: any) {
        const game = {
            type:     'Crop',
            gameType: 'crop',
            gameId: JSON.stringify({
                type:         'crop',
                performer_id: image.artistId,
                upload_id:    image.uploadId,
                crop_shape:   shape
            }),
            performerId:     image.artistId,
            performerGenres: this.artistData.artistGenres,
            performerName:   this.artistData.artistName,
            images: [
                {
                    url:         image.url,
                    imageinfo:   image.imageinfo,
                    uploadId:    image.uploadId,
                    cropDetails: { crop_shape: shape }
                }
            ]
        }

        return game;
    }

    cropImage(image: any, shape: any, returnToTab: string) {
        const games = [];
        if ( shape === 'all' ) {
            for ( let s of ['circle 1:1', 'rectangle 16:7', 'rectangle 4:3'] ) {
                if ( this.imageCanBeAdjusted(image, s )) {
                    games.push(this._cropImage(image, s));
                }
            }
        } else {
            games.push(this._cropImage(image,shape));
        }
        return new CropAllQueue(games, 'ArtistPage', () => this.$router.push({ 
            name: 'ArtDirectorArtist', 
            params: { artistId: this.artistData.artistId, tab: returnToTab }
        }))
    }

    //Flagging stuff
    flagRadio                          = 0;
    flagReason                         = '';
    flagObj: any                       = {};
    flagCrop                           = this.defaultCrop();
    flagCropScale                      = '0'
    flagTab: string |null              = null
    flagPage: number                   = 0
    isFlagModalActive                  = false;
    submittingFlag                     = false;
    showBackBtn                        = false;

    closeFlag() {
        this.flagPage          = 0;
        this.flagTab           = null;
        this.flagRadio         = 0;
        this.flagReason        = ''
        this.flagObj           = {};
        this.flagCrop          = this.defaultCrop();
        this.flagCropScale     = '0';
        this.wrongArtist       = null;
        this.isFlagModalActive = false;
        this.showBackBtn       = false;
    }

    defaultCrop() {
        return {
            area: { u1: 0, v1: 0, u2: 0, v2: 0 },
            horizontallyFlipped: false,
        };
    }

    openFlag(obj: any, tab: string, flagPage: number ) {
        this.flagTab           = tab;
        this.flagObj           = obj;
        this.flagPage          = flagPage;
        this.isFlagModalActive = true;
    }


    cropThisIsArtist(image: any, returnToTab: string) {
        const games = [];
        for ( let s of ['circle 1:1', 'rectangle 16:7', 'rectangle 4:3'] ) {
            if ( this.imageCanBeAdjusted(image, s )) {
                games.push(this._cropImage(image, s));
            }
        }
        games.forEach((g:any) => g.disableWrongArtist = true);
        return new CropAllQueue(games, 'ArtistPage', (bag: any[]) => {
            const artistId = this.artistData.artistId;
            const tab      = returnToTab;
            this.thisIsArtist(bag).then(r => {
                this.$router.push({ 
                    name: 'ArtDirectorArtist', 
                    params: { artistId, tab }
                })
            });
            }, true
        )
    }

    handleMoveArtist(payload: any) {
        const {artist, reason} = payload;
        if (!artist) {
            this.notArtist(reason);
        } else {
            this.moveImage(artist,reason);
        }
    }

    async thisIsArtist(crops: any[]) {
        const body = {
            type: 'flag',
            data: {
                option:     'KEEPARTIST', 
                reason:     null,
                crops:      crops,
                source:     'ArtistPage',
            },
            cropId:   null,
            uploadId: JSON.parse(crops[0].gameId).upload_id 
        }
        await this._submitFlag(body);
    }

    async ignore(image: any) {
        this.submittingFlag = false;
        try {
            this.submittingFlag = true;
            const body = { id: image.modId, keep: true }
            await axios.post('/api/artdirector/1/restricted/resolve', body);
            this.reload();
        } catch (e) {
        }
        this.submittingFlag = false;
    }

    async moveImage(artist:any, reason:string) {
        const body = {
            type: 'flag',
            data: {
                option:     'MOVEARTIST', 
                reason,
                crop:       null as any,
                result:     null as any,
                source:     'ArtistPage',
                moveTo:     artist.id,
                moveToName: artist.performer.name
            },
            cropId:   null,
            uploadId: this.flagObj.uploadId 
        }
        await this._submitFlag(body);
    }

    async notArtist(reason: string) {
        const body = {
            type: 'flag',
            data: {
                option:     'NOTARTIST', 
                reason,
                crop:       null as any,
                result:     null as any,
                source:     'ArtistPage',
            },
            cropId:   null,
            uploadId: this.flagObj.uploadId 
        }
        await this._submitFlag(body);
    }

    async adjust(result: string) {
        const body = {
            type: 'flag',
            data: {
                option: 'ADJUSTMENT',//Always require approval unless needs work
                reason: null,
                crop:   null as any,
                result: null as any,
                source: 'ArtistPage'
            },
            cropId:   null,
            uploadId: null
        }
        body.cropId      = this.flagObj.crops[0].key;
        body.data.crop   = this.flagCrop;
        body.data.result = result!
        await this._submitFlag(body);
    }

    async undoDeleteImage(image: any) {
        const { result } = this.$buefy.dialog.confirm({
            type: "is-danger",
            message: "Are you sure you want to undo the deletion of this image?",
            confirmText: "Yes, undo it",
            cancelText:  "Cancel",
            onConfirm: async () => {
                const body = {
                    type: 'flag',
                    data: {
                        option: 'UNDODELETE',//Always require approval unless needs work
                        reason: null,
                        crop:   null as any,
                        result: null as any,
                        source: 'ArtistPage'
                    },
                    cropId:   null,
                    uploadId: image.uploadId,
                }
                await this._submitFlag(body);
            },
            onCancel: () => {}
        })
    }

    async deleteImage(image: any) {
        const { result } = this.$buefy.dialog.confirm({
            type: "is-danger",
            message: "Are you sure you want to delete this photo?",
            confirmText: "Yes, delete it",
            cancelText:  "Cancel",
            onConfirm: async () => {
                const body = {
                    type: 'flag',
                    data: {
                        option: 'DELETE',//Always require approval unless needs work
                        reason: null,
                        crop:   null as any,
                        result: null as any,
                        source: 'ArtistPage'
                    },
                    cropId:   null,
                    uploadId: image.uploadId,
                }
                await this._submitFlag(body);
            },
            onCancel: () => {}
        })
    }

    async duplicate(image: any) {
        const body = {
            type: 'flag',
            data: {
                option: 'DUPLICATE',//Always require approval unless needs work
                reason: null,
                crop:   null as any,
                result: null as any,
                source: 'ArtistPage'
            },
            cropId:   null,
            uploadId: image.uploadId,
        }
        await this._submitFlag(body);
    }

    async _submitFlag(body: any) {
        this.submittingFlag = true;
        try {
            const res  = await axios.post('/api/artdirector/1/restricted', body)
            this.closeFlag();
            this.reload(this.activeTab)
            this.$toasted.show('Thank you for your submission! A moderator will be looking at this shortly.', { position: 'bottom-right', duration: 3000, type: 'success' });
        } catch (err) {
            console.log(err)
        }
        this.submittingFlag = false;
    }

    //end modal stuff

    _nonCrops    = [] as any[] | null
    _circles     = [] as any[] | null
    _mobile      = [] as any[] | null
    _rects       = [] as any[] | null
    _all         = [] as any[] | null
    _active      = [] as any[] | null
    _wrongArtist = [] as any[] | null

    images(type: string) {

        function sortfunc(a:any, b:any) {
            function isPending(x: any) { return x.votes == Infinity; }
            function isBad(x: any) { return x.crops[0].result != 'cropped' && !x.isWrongArtist }

            if (isBad(a) && !isBad(b)) return 1;
            if (!isBad(a) && isBad(b)) return -1;

            if (a.isWrongArtist  && !b.isWrongArtist ) return 1;
            if (!a.isWrongArtist && b.isWrongArtist )  return -1;

            if (isPending(a.crops[0]) && !isPending(b.crops[0])) return -1;
            if (!isPending(a.crops[0]) && isPending(b.crops[0])) return 1;

            let r = a.crops[0].result.localeCompare(b.crops[0].result);
            if (r != 0) return r;

            return b.crops[0].votes - a.crops[0].votes
        }
        function wrongArtistSort(a: any, b: any) {
            //send wrong artist to bottom
            if (a.isWrongArtist  && !b.isWrongArtist) return 1;
            if (!a.isWrongArtist && b.isWrongArtist ) return -1;

            //Group deleted and mod together
            if (a.isDeleted && !b.isDeleted)           return 1;
            if (!a.isDeleted && b.isDeleted)           return -1;
            if (a.isInModeration && !b.isInModeration) return 1;
            if (!a.isInModeration && b.isInModeration) return -1;

            return new Date(b.uploaded_on).getTime()  - new Date(a.uploaded_on).getTime()
        }

        let l = [];
        const arrays: Record<string, any> = {
            'NonCrops'       : this._nonCrops,
            'circle 1:1'     : this._circles,
            'rectangle 4:3'  : this._mobile,
            'rectangle 16:7' : this._rects, 
            'All'            : this._all,
        }
        const shapeFilter = (shape: string) => {
            if ( arrays[shape] ) return arrays[shape];
            arrays[shape] = this.allImages
            .filter( ( e : any ) => {
                if ( e.isDeleted || e.isInModeration ) return false;
                return ( e.crops || [] )
                .some( ( c: any ) => c.shape === shape)
            }).reduce((a: any[], v: any) => {
                a.push(...v.crops.filter((c:any) => (c.shape === shape)).map((c:any) => { return { ...v, crops: [c] }; }));
                return a;
            }, []).sort(sortfunc);
            return arrays[shape]
        }
        switch ( type ) {
            case 'All':
                if (this._all) return this._all
                this._all = this.allImages.sort(wrongArtistSort);
                return this._all
            case 'Active':
                if (this._active) return this._active
                this._active = this.allImages.filter( (image:any) => {
                    return !(image.isDeleted || image.isInModeration)
                })
                return this._active
            case 'circle 1:1':
                return shapeFilter(type)
            case 'rectangle 4:3':
                return shapeFilter(type)
            case 'rectangle 16:7':
                return shapeFilter(type)
            case 'wrong-artist':
                if (this._wrongArtist) return this._wrongArtist
                this._wrongArtist = this.allImages
                .filter( (image:any) => {
                    return image.isWrongArtist && !(image.isDeleted || image.isInModeration)
                })
                .sort(wrongArtistSort)
                return this._wrongArtist
            case 'NonCrops':
                if (this._nonCrops) return this._nonCrops
                this._nonCrops = this.allImages
                        .filter( ( image: any ) => {
                            if ( image.isDeleted || image.isInModeration || image.isWrongArtist ) return false;
                            if ( image.flaggedCircle || image.flaggedRect167 || image.flaggedRect43 ) return false;
                            if ( image.userPendingApproval ) return false;
                            return !image.crops || // no crops
                                (
                                    !image.flaggedCircle &&
                                    image.cropShapeBlacklist.every((c:string) => c !== 'circle 1:1') && 
                                    image.crops.every( ( crop: any ) => (crop.shape != 'circle 1:1'))
                                ) || // no circles
                                (
                                    !image.flaggedRect43 &&
                                    image.cropShapeBlacklist.every((c:string) => c !== 'rectangle 4:3') && 
                                    image.crops.every( ( crop: any ) => (crop.shape != 'rectangle 4:3'))
                                ) || // no mobile 
                                (
                                    !image.flaggedRect167 &&
                                    image.cropShapeBlacklist.every((c:string) => c !== 'rectangle 16:7') &&
                                    image.crops.every( ( crop: any ) => (crop.shape != 'rectangle 16:7')) 
                                )// no rects 
                        })
                        .sort(wrongArtistSort)
                return this._nonCrops
            default:
                throw new Error('Unknown image type value: ' + type)
        }
    }

    getPlayAllCrops(shape: string) {
         const crops = []
         let winner  = null
         for ( let image of this.images(shape)) {
             if ( image.userPendingApproval ) continue;
             if ( image.isWrongArtist )       continue;
             for ( let crop of image.crops || [] ) {
                 if ( crop.shape === shape && crop.result === 'cropped' ) {
                     crops.push({...crop, 
                                url:             image.url,
                                imageinfo:       image.imageinfo,
                                uploadId:        image.uploadId,
                                performerId:     image.artistId,
                                performerName:   this.artistData.artistName,
                                performerGenres: this.artistData.artistGenres,
                                winner:          this.mywinners[shape] === crop.key
                     });
                     if ( this.mywinners[shape] === crop.key ) {
                         winner = crops.pop();
                     }
                 }
             }
        }
        if ( crops.length > 1 || ( crops.length === 1 && winner )) {
            this.shuffle(crops);
            if ( winner) crops.unshift(winner);
        }
        return crops
    }

    shuffle(arr: any[]) {
        let i = arr.length, j, temp;
        while ( --i > 0 ) {
            j = Math.floor(Math.random()*(i+1));
            temp   = arr[j]
            arr[j] = arr[i]
            arr[i] = temp
        }
    }

    //Play alls
    get rect43PlayAll() {
        const crops = this.getPlayAllCrops('rectangle 4:3')
        if (crops.length > 1) {
            return new VoteAllQueue(crops, 'ArtistPage', () => this.$router.push({ name: 'ArtDirectorArtist', params: { artistId: this.artistData.artistId, tab: 'mobile' }}));
        }
    }
    get circlePlayAll() {
        const crops = this.getPlayAllCrops('circle 1:1')
        if (crops.length > 1) {
            return new VoteAllQueue(crops, 'ArtistPage', () => this.$router.push({ name: 'ArtDirectorArtist', params: { artistId: this.artistData.artistId, tab: 'avatars' }}));
        }
    }

    get rectPlayAll() {
        const crops = this.getPlayAllCrops('rectangle 16:7');
        if (crops.length > 1) {
            return new VoteAllQueue(crops, 'ArtistPage', () => this.$router.push({ name: 'ArtDirectorArtist', params: { artistId: this.artistData.artistId, tab: 'banners' }}));
        }
    }

    get nonCropPlayAll() {
        const shapeGames: Record<string, any[]> = {
            'circle 1:1':     [],
            'rectangle 16:7': [],
            'rectangle 4:3':  []
        }
        for ( let image of this.images('NonCrops')) {

            const flaggedShapeProps: Record<string,string> = {
                'circle 1:1':     'flaggedCircle',
                'rectangle 4:3':  'flaggedRect43',
                'rectangle 16:7': 'flaggedRect167'
            }

            const game = {
                    type:     'Crop',
                    gameType: 'crop',
                    gameId: {
                        type:         'crop',
                        performer_id: image.artistId,
                        upload_id:    image.uploadId,
                    },
                    performerId:     image.artistId,
                    performerGenres: this.artistData.artistGenres,
                    performerName:   this.artistData.artistName,
            } as any

            ['circle 1:1', 'rectangle 16:7', 'rectangle 4:3'].forEach((s:string) => {
                //Has shape
                if ( !( image.crops || []).some((e : any) => (e.shape === s)) &&
                     !image[flaggedShapeProps[s]] &&
                     !(image.cropShapeBlacklist || []).some((bs: any) => bs === s) &&
                     !image.isWrongArtist
                   )
                {
                       shapeGames[s].push({
                            ...game,
                            gameId: JSON.stringify({ ...game.gameId, crop_shape: s }),
                            images: [
                                {
                                    url:         image.url,
                                    imageinfo:   image.imageinfo,
                                    uploadId:    image.uploadId,
                                    cropDetails: { crop_shape: s }
                                }
                            ]
                        })
                }
            })
        }

        const crops = [];
        while ( shapeGames['circle 1:1'].length && 
                shapeGames['rectangle 16:7'].length &&
                shapeGames['rectangle 4:3'].length ) {
            if ( crops.length % 15 < 5 ) {
                crops.push(shapeGames['circle 1:1'].pop());
            } else if (crops.length % 15 >= 5 && crops.length < 10) {
                crops.push(shapeGames['rectangle 4:3'].pop());
            } else {
                crops.push(shapeGames['rectangle 16:7'].pop());
            }
        }

        for ( let shape in shapeGames ) {
            if ( shapeGames[shape] ) {
                while(shapeGames[shape].length) crops.push(shapeGames[shape].pop());
            }
        }

        if (crops.length) {
            return new CropAllQueue(crops, 'ArtistPage',
                () => this.$router.push({
                    name: 'ArtDirectorArtist',
                    params: {
                        artistId: this.artistData.artistId,
                        rank: "1"
                    }
                }));
        }
    }

    async getArtistInfo(artistId: string, pTab?: string) {
        this.loading = true;
        try {
            const res       = await axios.get(`/api/artdirector/1/artists/${artistId}`);
            const json      = res.data;
            this.artistData = {...this.artistData, ...json, artistId};

            const images = [];
            const rankings: Record<string, any[]> = {}

            this.mywinners  = {}
            for ( let image of json.images ) {
                image.showModal  = false;
                /*
                determine canAdjust      - image that is accepted and not cropped for any crop shape
                          canRank<shape> - at least two crops for shape AND you havent voted against all available crops 
                 */

                //if ( image.status === 'accept' ) {
                    for (let crop of image.crops || []) {
                        //Store map of ranked crops by shape
                        if ( !rankings[crop.shape]) rankings[crop.shape] = [];
                        if ( crop.result === 'cropped') rankings[crop.shape].push(crop);
                        if (crop.vote    === "for")     this.mywinners[crop.shape] = crop.key;
                    }
                    //}

                if (image.faces) {
                    image.faces = image.faces.map((a: any) => {
                        return {
                            u1: a.box.x / image.imageinfo.width,
                            v1:  a.box.y / image.imageinfo.height,
                            u2: (a.box.x + a.box.width) / image.imageinfo.width,
                            v2: (a.box.y + a.box.height) / image.imageinfo.height,
                            score: a.score
                        };
                    });
                }

                if (image.crops) {
                    image.crops = image.crops.sort((a:any, b:any) => b.votes - a.votes);
                }

                //Handle user uploads
                if ( image.userUploadData != null ) {
                    image.crops = image.userUploadData.crops.map((c: any) => { return { 
                            ...c, 
                            shape: c.crop_shape, 
                            result: c.result, 
                            votes: Infinity, 
                            gameId: new Date().toISOString(),
                            created: image.created
                        } 
                    })
                }

                images.push(image);
            }

            //Sort crops and store rank
            for ( let shape in rankings ) {
                let map: Record<number,number> = {}
                let sorted                     = rankings[shape].sort((a:any, b:any) => b.votes - a.votes)
                for ( let i = 0; i < sorted.length; i++) {
                    map[sorted[i].key] = i + 1
                }
                this.rankings[shape] = map 
            }

            this.allImages   = images


            if (this.$route.params.tab) {
                this.activeTab = this.$route.params.tab

            } else if (this.$route.params.rank) {
                if (this.canVote("circle 1:1")) {
                    this.activeTab = "avatars";
                } else if (this.canVote("rectangle 16:7")) {
                    this.activeTab = "banners";
                } else if (this.canVote("rectangle 4:3")) {
                    this.activeTab = "mobile";
                } else {
                    //get first tab that can be displayed
                    const wheel = [{ key: 'NonCrops', tab: 'unadjusted' }, { key: 'circle 1:1', tab: 'avatars' }, { key: 'rectangle 16:7', tab: 'banners' },{ key: 'rectangle 4:3', tab: 'mobile' }];
                    for ( let t of wheel ) {
                        if ( this.images(t.key).length ) {
                            this.activeTab = t.tab;
                            break;
                        }
                    }
                    //default will be unadjusted, bad state at this point since tabs should not be shown.
                }
            } else {
                if (this.images("NonCrops").length > 0)
                    this.activeTab = "unadjusted";
                else if (this.images("circle 1:1").length > 0)
                    this.activeTab = "avatars";
                else if (this.images("rectangle 16:7").length > 0)
                    this.activeTab = "banners";
                else if (this.images("rectangle 4:3").length > 0)
                    this.activeTab = "mobile";
                else
                    this.activeTab = "unadjusted";
            }

            //Query param overrides above;
            if ( this.$route.query.tab || pTab ) {
                let tab = (this.$route.query.tab || pTab) as string;

                const validTabs = {
                    'avatars':   'circle 1:1', 
                    'banners':   'rectangle 16:7', 
                    'mobile':    'rectangle 4:3', 
                    'unadjusted':'NonCrops', 
                    'all':       'All',
                    'wrong-artist':'wrong-artist'
                } as any;


                if ( validTabs[tab] ) {
                    if ( tab === 'all' && this.user!.role === 'Normal' ) {
                    } else {
                        if ( this.images(validTabs[tab]).length > 0 ) {
                            this.activeTab = tab 
                        }
                    }
                }
                this.$router.replace({path:this.$route.path})
            }
            
        } catch (err) {
            console.log(err);
            //XXX
        } finally {
            this.loading = false;
        }
    }

    gotoUpload() {
        this.$router.push({
            name: 'ArtDirectorArtistUpload',
            params: {
                artistId: this.artistData.artistId
            }
        });
    }

    canVote(shape: string, min: number = 1) {
        let good = 0;
        for (let image of this.images(shape)) {
            if (image.crops[0].result == "cropped")
                good++;
            if (good >= min)
                return true;
        }
        return false;
    }

    async selectFavorite(crop: any, cropId: number, shape: string) {
        let undo = false
        for ( let winner of Object.values(this.mywinners) ) {
            if (cropId === winner) {
                undo = true;
                break;
            };
        }

        const errorText = undo ? 'unfavoriting this adjustment' : 'favoriting this adjustment';
        try {
            if ( undo ) {
                this.mywinners = {...this.mywinners, [shape]: -1 }
                crop.votes--;
                const res = await axios.post('/api/artdirector/1/vote/clear',     { winner: cropId }) 
            } else {
                if ( this.mywinners[shape] !== -1 ) {
                    const prevWinner = this.images(shape).filter((image:any) => image.crops[0].key === this.mywinners[shape])[0]
                    if (prevWinner) prevWinner.crops[0].votes--;
                }

                this.mywinners = {...this.mywinners, [shape]: cropId }
                crop.votes++;
                const res  = await axios.post('/api/artdirector/1/vote', { winner: cropId, source: 'ArtistPage' }) 
            }
        } catch (err) {
            console.log(err)
            this.$toasted.error(`Something went wrong while ${errorText}. Please try again`, { position: 'bottom-right', duration: 2000 })
        }
    }
}
