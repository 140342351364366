
import axios                    from 'axios';
import { Vue, Component, Prop } from 'vue-property-decorator'

const discoveredComponents : any[] = []
const discoveredComponentsById : Record<string, any> = { }

const componentloader = require.context('./editors/', false, /\.vue$/i)
for (let comp of componentloader.keys().map(x => componentloader(x).default)) {
    if (comp.options.timelineEditorInfo) {
        discoveredComponents.push(comp.options.timelineEditorInfo);
        discoveredComponentsById[comp.options.timelineEditorInfo.id] = comp;
    }
}

@Component
export default class TimelineEditor extends Vue {
    @Prop({required: true}) artist!: any;

    selected_component_info?: any = null;
    components: any[] = [];
    searchTerm: string = ""

    $refs!: {
        typeInput: HTMLInputElement
    }

    get filteredComponents() {
        return this.components.filter((c) => c.displayName.toLowerCase().indexOf(this.searchTerm.toLowerCase()) >= 0);
    }

    get selected_component() {
        if (this.selected_component_info)
            return discoveredComponentsById[this.selected_component_info.id];
        return undefined;
    }

    created() {
        this.components = discoveredComponents;
        console.log(discoveredComponents)
        console.log(discoveredComponentsById)
    }
}
