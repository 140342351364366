import Vue                           from 'vue'
import axios, { AxiosRequestConfig } from 'axios'
import { io, Socket }                from "socket.io-client";

// @ts-ignore
import RoonAuth                      from '@roonlabs/vue-roon-login';

export interface Tier {
    number:  number;
    score:   number;
    percent: number;
}

interface User {
    role:       string;
    tier:       Tier;

    profileId?: string;
    name?:      string;
    photoUrl?:  string;
}

interface AuthType {
    user:              User | null;
    socket:            Socket | null;
    messages:          Message[];
    numMessages:       number;

    setViewing(messageId: string, viewing: boolean) : Promise<void>;
}

interface ViewingInfo {
    profileId: string,
    photoUrl: string,
    email: string,
    name: string,
    date: Date,
}
interface Message {
    messageType: string,
    messageId:   string,

    title:       string,
    subtitle:    string,

    viewing:   Record<string,ViewingInfo>,
};

export interface FlagModeratorMessage extends Message {
    reason: string
}

export interface FlagCropModeratorMessage extends FlagModeratorMessage {
    cropId: any,
}

export interface FlagImageModeratorMessage extends FlagModeratorMessage {
    uploadId: any,
}

//Reactive Auth object for app
const Auth: AuthType = Vue.observable({
    user:        null,
    socket:      null,
    messages:    [],
    numMessages: 0,

    async setViewing(messageId: string, viewing: boolean) {
        await ensureSocket();
        (this as any).socket!.emit("modq:set_viewing", { messageId, viewing });
    }
})

async function selectProfile(profileId: string) {
    await axios.post(`/api/session/1/profiles/${profileId}`);
    await refresh();
}

async function getProfiles() {
    try {
        const res  = await axios.get('/api/session/1/profiles')
        return res.data.profiles;
    } catch (err) {
        throw err;
    }
}

async function ensureSocket() {
    if (Auth.user?.profileId && !Auth.socket) {
        Auth.socket = io();

        Auth.socket.on("connect", () => {
            console.log("CONNECT", Auth.socket!.id);
        });

        Auth.socket.onAny((event:string, a:any) => {
            console.log("GOT", event, a);

            Auth.numMessages = a.numMessages || Auth.numMessages;

            if (event == "modq:set") {
                const m = a.messages.map((x:any) => { return { viewing: {}, ...x }; });
                if ( Auth.user!.role === 'admin' ) {
                    Auth.messages = m
                } else {
                    Auth.messages = m.filter((msg: any) => msg.profile.profileId != Auth.user!.profileId)
                }

            } else if (event == "modq:add") {

                let toadd = Auth.user!.role === 'admin' ? a.messages.length : a.messages.filter((m:any) => m.profile.profileId != Auth.user!.profileId).length;
                Auth.numMessages += toadd;

                const m = a.messages.map((x:any) => { return { viewing: {}, ...x }; });
                if ( Auth.user!.role === 'admin' ) {
                    Auth.messages.push(...m);
                } else {
                    Auth.messages.push(...m.filter((msg: any) => msg.profile.profileId != Auth.user!.profileId))
                }
                Auth.messages.sort((a: any, b:any) => { return new Date(a.date).getTime() - new Date(b.date).getTime(); });

            } else if (event == "modq:remove") {
                let toremove = 0;
                for (let messageId of a.messageids) {
                    const [m] = Auth.messages.splice(Auth.messages.findIndex((msg: any) => msg.id === messageId), 1) as any;
                    if (m && (Auth.user!.role === 'admin' || m.profile.profileId != Auth.user!.profileId)) toremove++;
                }
                Auth.numMessages -= toremove;

            } else if (event == "modq:set_viewing") {
                let m = Auth.messages[Auth.messages.findIndex((msg: any) => msg.id === a.messageId)];
                if (m) {
                    if (a.date) {
                        Vue.set(m.viewing, a.profileId, {
                            date:      new Date(a.date),
                            name:      a.name,
                            email:     a.email,
                            profileId: a.profileId,
                            photoUrl:  a.photoUrl
                        });
                    } else {
                        Vue.delete(m.viewing, a.profileId);
                    }
                }
            }
        });

        Auth.socket.on("disconnect", () => {
            console.log("DISCONNECT", Auth.socket!.id);
        });
    }
}

async function signOut() {
    await RoonAuth.logout({ to: '/info' });
    await clearAuth();
}

async function refresh() {
    try {
        const res  = await axios.get('/api/session/1/refresh')
        const json = res.data;
        Auth.user  = json.user;
        ensureSocket();
    } catch {
        await clearAuth();
    }
}

async function clearAuth() {
    if (Auth.socket) {
        Auth.socket.close();
        Auth.socket = null;
    }

    Auth.user = null;
    try {
    } catch { }
}

export {
    Auth,
    signOut,
    refresh,
    selectProfile,
    getProfiles,
    clearAuth,
}
