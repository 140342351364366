
import { Vue, Component, Prop, Model, Watch, PropSync } from 'vue-property-decorator'

import type { CropData, UploadImage }                        from '../interfaces';

interface Size {
    width:  number;
    height: number;
}

interface UVPair {
    u1: number;
    u2: number;
    v1: number;
    v2: number;
}

function rn(n:number) {
    return +(n.toFixed(5));
}
function rs(n:Size) {
    n.width  = rn(n.width);
    n.height = rn(n.height);
    return n;
}
function rp(n:UVPair) {
    n.u1 = rn(n.u1);
    n.u2 = rn(n.u2);
    n.v1 = rn(n.v1);
    n.v2 = rn(n.v2);
    return n;
}

@Component
export default class Croppable extends Vue {
    @Prop({required: true}) image!:    UploadImage;

    @Prop({default: false}) readonly!:   boolean;
    @Prop({default: null})  overlay!:    string | null;
    @Prop({default: false}) keyboard!:   boolean;
    @Prop({default: false}) debug!:      boolean;
    @Prop({
        default: 'circle',
        validator(value: string) {
            return ['circle 1:1', 'square 1:1', 'rectangle 16:7', 'rectangle 4:3'].indexOf(value) !== -1
        }
    }) shape!: string;

    @PropSync('scale') syncedScale!: string;

    @Watch('scale')
    scaleChanged() {
        if (this.readonly) return;

//        console.log("scale changed:", this.syncedScale);

        if (this.syncedScale == this.computeScale())
            return;

        const ratio = (this.area.u2 - this.area.u1) / (this.area.v2 - this.area.v1);
        let a,max,min;
        if (this.imageAspectRatio > this.cropAspectRatio) {
            a = this.area.v2 - this.area.v1;
            max = this.bounds.v2 - this.bounds.v1;
            min = this.zoomInLimit.height;

        } else {
            a = this.area.u2 - this.area.u1;
            max = this.bounds.u2 - this.bounds.u1;
            min = this.zoomInLimit.width;
        }

        const s = 1-Number(this.syncedScale);
        let n = s * (max-min) + min;
        let diff = (n-a)/2;

        if (this.imageAspectRatio > this.cropAspectRatio) {
            this.area.u1 -= diff * ratio;
            this.area.v1 -= diff;
            this.area.u2 += diff * ratio;
            this.area.v2 += diff;
        } else {
            this.area.u1 -= diff;
            this.area.v1 -= diff / ratio;
            this.area.u2 += diff;
            this.area.v2 += diff / ratio;
        }
        this.fixZoom(ratio);
        rp(this.area);

        if (this.showHand)
            this.showHand = false;

        this.recalc(true);
    }

    @Model('crop') crop?: CropData

    hFlip   = false;
    area             = { u1: 0, u2: 1, v1: 0, v2: 1 };
    bounds           = { u1: 0, u2: 1, v1: 0, v2: 1 };
    zoomInLimit      = { width: 0, height: 0 };

    cropAspectRatio  = 1;
    imageAspectRatio = 1;
    containerWidth   = 0.0;
    containerHeight  = 0.0;

    showHand         = true;
    shown            = false;
    loaded           = false;

    lastMousePos     = { clientX: 0.0, clientY: 0.0 }

    $refs!:          {
        img:         HTMLImageElement,
        container:   HTMLDivElement,
        debugBox:    HTMLDivElement
    }

    get maskStyle() {
        return {
            zIndex:       20,
            position:     'absolute',
            top:          `0`,
            left:         '50%',
            transform:    `translateX(-50%)`,
            borderRadius:  this.shape === 'circle 1:1' ? '50%' : null,
            width:        '100%',
            height:       '100%',
            pointerEvents:'none',
        }
    }

    get bgStyle() {
        return {
            background: `linear-gradient(${this.image.imageinfo.extendColor || "black"}, ${this.image.imageinfo.extendColor || "black"}),black`
        };
    }

    get imgStyle() {
        return {
            backgroundColor: 'black',
            position:        'absolute',
            left:            '0',
            transform: `
                        translate(-50%, -50%)
                        scale(${(this.containerWidth  / this.image.imageinfo.width  / (this.area.u2 - this.area.u1)).toString()}, ${(this.containerHeight / this.image.imageinfo.height / (this.area.v2 - this.area.v1)).toString()})
                        translate(${-1 * this.area.u1 * this.image.imageinfo.width}px, ${-1 * this.area.v1 * this.image.imageinfo.height}px)
                        translate(50%, 50%)
                        scaleX(${this.hFlip ? -1 : 1})
                        `
        }
    }

    keyboardManager(e: any) {
        if(this.keyboard && window.getComputedStyle(this.$refs.container).display !== 'none') {
            const key = e.which;
            switch (key) {
                case 37:
                    this.arrowMove('left')
                    break;
                case 38:
                    this.arrowMove('up')
                    break;
                case 39:
                    this.arrowMove('right')
                    break;
                case 40:
                    this.arrowMove('down')
                    break;
                case 65: // a
                    this.zoom('in')
                    break;
                case 90: // z
                    this.zoom('out')
                    break;
            }
        }
    }

    imageLoaded() {
        this.loaded = true;
        this.setup("imageLoaded")
        this.$emit('image-loaded', {});
    }

    imageVisibilityChanged(isVisible: boolean) {
        if (isVisible) {
            this.shown = true;
            this.setup("imageVisibilityChanged")
        }
    }

    setup(why: string, resetting?: boolean) {
        // console.log("TRYING SETUP due to ", why, this.shape)
        if (!this.sourceImageLoaded || !this.shown || !this.loaded) return;

        this.containerWidth  = this.$refs.container.offsetWidth;
        this.containerHeight = this.$refs.container.offsetHeight;
        // console.log("SETTING UP due to ", why, this.shape, this.containerWidth + " x " +  this.containerHeight)

        switch (this.shape) {
            case 'rectangle 16:7':
                this.cropAspectRatio = 16 / 7;
                break;
            case 'rectangle 4:3':
                this.cropAspectRatio = 4 / 3;
                break;
            default:
                this.cropAspectRatio = 1 / 1;
                break;
        }

        this.imageAspectRatio = this.image.imageinfo.width / this.image.imageinfo.height;

        let xDiameter;
        let yDiameter;
        if (this.imageAspectRatio >= this.cropAspectRatio) {
            xDiameter = this.cropAspectRatio / this.imageAspectRatio;
            yDiameter = 1;
        } else {
            yDiameter = this.imageAspectRatio / this.cropAspectRatio;
            xDiameter = 1;
        }

        if (!resetting && this.crop?.area && this.crop.area.u1 != this.crop.area.u2) {
            this.area = { 
                u1: this.crop.area.u1,
                v1: this.crop.area.v1,
                u2: this.crop.area.u2,
                v2: this.crop.area.v2
            };
            this.hFlip = !!this.crop.horizontallyFlipped;
        } else {
            this.area = rp({
                u1: 0.5-(xDiameter/2),
                v1: 0.5-(yDiameter/2),
                u2: 0.5+(xDiameter/2),
                v2: 0.5+(yDiameter/2),
            });
        }
        this.zoomInLimit = rs({
            width:  xDiameter / 2,
            height: yDiameter / 2,
        });
        this.bounds = {
            u1: 0,
            v1: 0,
            u2: 1,
            v2: 1,
        }
        if (this.shape === 'circle 1:1' && this.image.imageinfo.extendColor) {
            if (this.imageAspectRatio > 1) {
                this.bounds.v1 = 0.5 - this.imageAspectRatio/2;
                this.bounds.v2 = 0.5 + this.imageAspectRatio/2;
            } else {
                this.bounds.u1 = 0.5 - 1/this.imageAspectRatio/2;
                this.bounds.u2 = 0.5 + 1/this.imageAspectRatio/2;
            }

            let diff = this.bounds.u2 - this.bounds.u1;
            this.bounds.u1 -= (0.5 * diff) / 2;
            this.bounds.u2 += (0.5 * diff) / 2;
            diff = this.bounds.v2 - this.bounds.v1;
            this.bounds.v1 -= (0.5 * diff) / 2;
            this.bounds.v2 += (0.5 * diff) / 2;
            rp(this.bounds);
        }

        this.recalc()
    }

    computeScale() {
        if (this.imageAspectRatio > this.cropAspectRatio) {
            return rn(1-(this.area.v2   - this.area.v1   - this.zoomInLimit.height) /
                     (this.bounds.v2 - this.bounds.v1 - this.zoomInLimit.height)).toString();
        } else {
            return rn(1-(this.area.u2   - this.area.u1   - this.zoomInLimit.width) /
                     (this.bounds.u2 - this.bounds.u1 - this.zoomInLimit.width)).toString();
        }
    }

    recalc(dontSetScale = false) {
        if (!dontSetScale) this.syncedScale = this.computeScale()
        this.$emit('crop', { area: Object.assign({}, this.area), horizontallyFlipped: this.hFlip });
    }

    reset() {
        this.setup("reset", true)
        this.recalc();
    }
    flip() {
        this.hFlip = !this.hFlip;
        this.recalc();
    }

    fixZoom(ratio: number) {
        // check zoom-in limit.
        let diff = this.zoomInLimit.width - (this.area.u2 - this.area.u1);
        if (diff > 0) {
            diff /= 2;
            this.area.u1 -= diff;
            this.area.v1 -= diff / ratio;
            this.area.u2 += diff;
            this.area.v2 += diff / ratio;
        }

        // check zoom-out limit X
        diff = (this.area.u2 - this.area.u1) - (this.bounds.u2 - this.bounds.u1);
        if (diff > 0) {
            diff /= 2;
            this.area.u1 += diff;
            this.area.v1 += diff / ratio;
            this.area.u2 -= diff;
            this.area.v2 -= diff / ratio;
        }

        // check zoom-out limit Y
        diff = (this.area.v2 - this.area.v1) - (this.bounds.v2 - this.bounds.v1);
        if (diff > 0) {
            diff /= 2;
            this.area.u1 += diff * ratio;
            this.area.v1 += diff;
            this.area.u2 -= diff * ratio;
            this.area.v2 -= diff;
        }

        // check if off the left side
        diff = this.area.u1 - this.bounds.u1;
        if (diff < 0) {
            this.area.u1 -= diff;
            this.area.u2 -= diff;
        }

        // check if off the top side
        diff = this.area.v1 - this.bounds.v1;
        if (diff < 0) {
            this.area.v1 -= diff;
            this.area.v2 -= diff;
        }

        // check if off the right side
        diff = this.bounds.u2 - this.area.u2;
        if (diff < 0) {
            this.area.u1 += diff;
            this.area.u2 += diff;
        }

        // check if off the bottom side
        diff = this.bounds.v2 - this.area.v2;
        if (diff < 0) {
            this.area.v1 += diff;
            this.area.v2 += diff;
        }
    }

    zoom(direction: string, amount: number = 2) {
        const ratio = (this.area.u2 - this.area.u1) / (this.area.v2 - this.area.v1);
        let diff = (this.area.u2 - this.area.u1) / this.containerWidth * amount;
        switch(direction) {
            case 'out':
                this.area.u1 -= diff;
                this.area.v1 -= diff / ratio;
                this.area.u2 += diff;
                this.area.v2 += diff / ratio;
                this.fixZoom(ratio);
                rp(this.area);
                break;

            case 'in':
                this.area.u1 += diff;
                this.area.v1 += diff / ratio;
                this.area.u2 -= diff;
                this.area.v2 -= diff / ratio;
                this.fixZoom(ratio);
                rp(this.area);
                break;
        }
        this.recalc();
    }

    arrowMove(direction: string) {
        switch(direction) {
            case 'up':    this.move(0, -(this.area.v2 - this.area.v1) / this.containerHeight  ); break;                                      
            case 'down':  this.move(0,  (this.area.v2 - this.area.v1) / this.containerHeight  ); break;                                      
            case 'left':  this.move(   -(this.area.u2 - this.area.u1) / this.containerWidth, 0); break;                                      
            case 'right': this.move(    (this.area.u2 - this.area.u1) / this.containerWidth, 0); break;
        }
        this.recalc();
    }

    move(deltaX: number, deltaY: number) {
        if (this.area.u1 + deltaX < this.bounds.u1) deltaX = this.bounds.u1 - this.area.u1;
        if (this.area.v1 + deltaY < this.bounds.v1) deltaY = this.bounds.v1 - this.area.v1;

        if (this.area.u2 + deltaX > this.bounds.u2) deltaX = this.bounds.u2 - this.area.u2;
        if (this.area.v2 + deltaY > this.bounds.v2) deltaY = this.bounds.v2 - this.area.v2;

        this.area.u1 += deltaX;
        this.area.u2 += deltaX;
        this.area.v1 += deltaY;
        this.area.v2 += deltaY;
        rp(this.area);
    }

    //Desktop
    mousedown(e: any) {
        this.showHand = false;
        //Disable right click
        if ('which'  in e && e.which  === 3)  return;
        if ('button' in e && e.button === 2) return;
        e.preventDefault();
        this.lastMousePos.clientX = e.clientX;
        this.lastMousePos.clientY = e.clientY;
        document.onmousemove      = this.mousemove;
        document.onmouseup        = this.mouseup;
    }

    mousemove(e: any) {
        this.showHand = false;
        e.preventDefault();
        const deltaX: number = (e.clientX - this.lastMousePos.clientX) * (this.area.u2 - this.area.u1) / this.containerWidth;
        const deltaY: number = (e.clientY - this.lastMousePos.clientY) * (this.area.v2 - this.area.v1) / this.containerHeight;

        this.lastMousePos.clientX = e.clientX;
        this.lastMousePos.clientY = e.clientY;

        this.move(-deltaX, -deltaY);
        this.recalc();
    }

    mouseup() {
	this.showHand = false;
        document.onmouseup   = null;
        document.onmousemove = null;
    }

    mousewheel(e: any) {
	this.showHand = false;
        e.preventDefault();

        const delta = e.deltaY * 0.001;
        if (delta < 0) {
            this.syncedScale = Math.min(1, Number(this.syncedScale) - delta).toString();
        } else {
            this.syncedScale = Math.max(0, Number(this.syncedScale) - delta).toString();
        }
    }

    //Touch Screen
    touchstart(e: any) {
        this.showHand = false;
        e.preventDefault()

        this.lastMousePos.clientX = e.touches[0].clientX;
        this.lastMousePos.clientY = e.touches[0].clientY;
        document.ontouchmove     = this.touchmove;
        document.ontouchend      = this.touchend;
    }

    touchmove(e: any) {
	if (this.showHand)
	    this.showHand = false;
        e.preventDefault();
        e = e.touches[0]
        const deltaX: number = (e.clientX - this.lastMousePos.clientX) * (this.area.u2 - this.area.u1) / this.containerWidth;
        const deltaY: number = (e.clientY - this.lastMousePos.clientY) * (this.area.v2 - this.area.v1) / this.containerHeight;

        this.lastMousePos.clientX = e.clientX;
        this.lastMousePos.clientY = e.clientY;

        this.move(-deltaX, -deltaY);
        this.recalc();
    }

    touchend() {
	if (this.showHand)
	    this.showHand = false;
        document.ontouchend = null;
        document.ontouchmove= null;
    }

    debugMouseDown(e: any) {
        e.preventDefault();
        this.lastMousePos.clientX = e.clientX;
        this.lastMousePos.clientY = e.clientY;
        document.onmousemove     = this.debugMouseMove;
        document.onmouseup       = this.debugMouseUp;
    }

    debugMouseMove(e: any) {
        e.preventDefault();
        const deltaX: number = e.clientX - this.lastMousePos.clientX
        const deltaY: number = e.clientY - this.lastMousePos.clientY

        this.lastMousePos.clientX = e.clientX;
        this.lastMousePos.clientY = e.clientY;
        this.$refs.debugBox.style.top = `${this.$refs.debugBox.offsetTop + deltaY}px`
        this.$refs.debugBox.style.left= `${this.$refs.debugBox.offsetLeft+ deltaX}px`
    }
    debugMouseUp() {
        document.onmouseup   = null;
        document.onmousemove = null;
    }

    start() {
        setTimeout(() => {
	    this.showHand = false;
        }, 2000)
    }

    sourceImageLoaded = false;
    created() {
        this.loaded            = false;
        this.shown             = false;
        this.sourceImageLoaded = false;

        // XXX do we need this sourceimage or should we use the $refs.img ?
        const sourceImage = new Image();
        sourceImage.onload = () => {
            this.sourceImageLoaded = true;
            this.setup("sourceImage.onload");
        }
        sourceImage.crossOrigin = 'anonymous';
        sourceImage.src = this.image.url;


        // console.log('created', this.image.url);
    }

    mounted() {
        window.addEventListener('resize', this.handleResize);
        if (!this.readonly)
            document.addEventListener('keydown', this.keyboardManager)
    }

    beforeDestroy() {
        document.removeEventListener('keydown', this.keyboardManager)
        if (!this.readonly)
            window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        if (!(this.containerWidth  === this.$refs.container.offsetWidth &&
              this.containerHeight === this.$refs.container.offsetHeight )) {
            this.setup("resize")
        }
    }
}
