import Vue, { PropType } from 'vue';
import { Game }          from '../interfaces';

export default Vue.extend({
    props: {
        game: {
            type: Object as PropType<Game>,
            required: true
        },
        isActive: {
            type: Boolean,
            required: true
        },
    },
    watch: {
        isActive: {
            handler(newVal, oldVal) {
                this.itemLoaded(0);
            }
        }
    },
    data() {
        return { started: false, loadedItems: 0, itemsToLoad: 0, startTime: 0.0 }
    },
    methods: {
        start()  { console.log('start not implemented') },
        submit(type: string, results: any) {
            this.$emit('completed', { payload: results})
        },
        itemLoaded(n: number) {
            this.loadedItems += n;
            if(this.loadedItems >= this.itemsToLoad && this.isActive && !this.started) {
                this.started = true;
                this.$emit('started', {  })
                this.start();
            }
        }
    },
})
