
import { Vue, Component, Prop, Model } from 'vue-property-decorator'

type Option = {
    id: string,
    display?: string
}

@Component
export default class FlagDialog extends Vue {

    @Prop({ required: true }) options!: Option[]
    @Prop() info?:  any
    @Prop() title?: string 

    @Model('active', { default: false }) active!: boolean

    radio: string | null = null;
    other: string | null = null;

    close() {
        this.radio = null; 
        this.other = null;
        this.$emit('active', false)
    }

    report() {
        //info is arbitrary object passed in as prop
        this.$emit('report', { option: this.radio, reason: this.other, info: this.info });
        this.$emit('active', false);
        this.radio      = null; 
        this.other      = null;
    }
}
