
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

import type { Face, Crop, UploadImage }           from '../interfaces';

const cropshapecolors: Record<string,string> = {
    'circle 1:1':    '#66ff00',
    'rectangle 16:7':'magenta',
    'rectangle 4:3': 'yellow',
};

@Component
export default class AdminImage extends Vue {
    @Prop({required: true})   image!:     UploadImage
    @Prop({default: ()=>[]})  crops!:     Crop[]
    @Prop({default: ()=>[]})  faces!:     Face[]
    @Prop({default: '200px'}) maxWidth!:  string;
    @Prop({default: '160px'}) maxHeight!: string;
    @Prop({default: false})   pointer!:   boolean;

    @Watch("crops") onCropsChanged() { this.recompute(); }
    @Watch("faces") onFacesChanged() { this.recompute(); }

    // how to display the image (extra bounds and flip) 
    area: { u1: number, v1: number, u2: number, v2: number } | null = null;
    hFlipImage = false;

    // The pixels size of the background (usually obscured, but not when zoomed
    // past outside edges).
    bgWidth:  number = 0;
    bgHeight: number = 0;

    // The pixels size of the image itself.
    imgWidth:  number = 0;
    imgHeight: number = 0;

    pct: number = 100;

    get wrapStyle() {
        return {
            position:           'relative',
            width:              '100%',
            paddingTop:         ((this.bgHeight / this.bgWidth) * 100) + "%",
        }
    }
    get bgStyle() {
        return {
            background:         `linear-gradient(${this.image.imageinfo.extendColor || "black"},${this.image.imageinfo.extendColor || "black"}),black`,
            position:           'absolute',
            width:              "100%",
            height:             "100%",
            display:            'flex',
            top: 0,
            left: 0,
            justifyContent:     'center',
            alignItems:         'center',
        }
    }
    get imgStyle() {
        return {
            backgroundImage:    `url("${this.image?.url}")`,
            backgroundSize:     '100%',
            position:           'absolute',
            transform:          `scaleX(${this.hFlipImage ? -1 : 1})`,
            width:              `${100 * this.imgWidth / this.bgWidth}%`,
            height:             `${100 * this.imgHeight / this.bgHeight}%`,
            left:               `${this.area ? (100 * (-this.area?.u1) / (this.area.u2 - this.area.u1)) : 0}%`,
            top:                `${this.area ? (100 * (-this.area?.v1) / (this.area.v2 - this.area.v1)) : 0}%`,
        }
    }

    cropStyle(crop: Crop) {
        if (!this.area) return {};

        const style = {
            position: 'absolute',
            border:   `2px solid ${cropshapecolors[crop.shape]}`,
            borderRadius: crop.shape === 'circle 1:1' ? '50%' : 'none'
        }


        if (crop.horizontallyFlipped && !this.hFlipImage) {
            return {
                ...style,
                left:   `${100 - crop.area.u2 * 100}%`,
                top:    `${crop.area.v1 * 100}%`,
                right:  `${crop.area.u1 * 100}%`,
                bottom: `${100 - crop.area.v2 * 100}%`,
            }
        } else {
            return {
                ...style,
                left:   `${crop.area.u1 * 100}%`,
                top:    `${crop.area.v1 * 100}%`,
                right:  `${100 - crop.area.u2 * 100}%`,
                bottom: `${100 - crop.area.v2 * 100}%`,
            }
        }
    }
    faceStyle(face: Face) {
        if (!this.area) return {};

        const style = {
            position: 'absolute',
            border:   `2px solid #00ffff`
        }

        return {
            ...style,
            left:   `${face.u1 * 100}%`,
            top:    `${face.v1 * 100}%`,
            right:  `${100 - face.u2 * 100}%`,
            bottom: `${100 - face.v2 * 100}%`,
        }
    }

    mounted() {
        this.recompute();
    }

    recompute() {
        if (!this.image)
            return;

        this.area = { u1: 0, v1: 0, u2: 1, v2: 1 };

        let countOfFlips = 0;
        for ( let crop of this.crops ) {
            this.area.u1 = Math.min(crop.area.u1, this.area.u1);
            this.area.v1 = Math.min(crop.area.v1, this.area.v1);
            this.area.u2 = Math.max(crop.area.u2, this.area.u2);
            this.area.v2 = Math.max(crop.area.v2, this.area.v2);
            if (crop.horizontallyFlipped) countOfFlips++;
        }

        this.hFlipImage = countOfFlips > 0 && countOfFlips == this.crops.length;

        this.imgWidth  = this.image.imageinfo.width;
        this.imgHeight = this.image.imageinfo.height;
        this.bgWidth   = Math.max(this.imgWidth, this.imgWidth * (this.area.u2 - this.area.u1));
        this.bgHeight  = Math.max(this.imgHeight, this.imgHeight * (this.area.v2 - this.area.v1));

        if (this.bgHeight > this.bgWidth) {
            this.pct = 100 / (this.bgHeight / this.bgWidth);
        }
    }
}

