import { Auth }         from '@/core/auth';
import pageIndex        from "./views/index.vue"
import pageDefault      from "./views/default.vue"
import pageArtist       from "./views/artist.vue"
import pageArtistUpload from "./views/artistupload.vue"
import pageModerate     from "./views/moderate.vue"
import pageLeaderboard  from "./views/leaderboard.vue"
import pageWork         from "./views/work.vue"

const prefix = 'ArtDirector';

export default {
    path: '/artdirector',
    component: pageIndex,
    children: [
        {
            path: '',
            component: pageDefault,
            name: prefix + 'Default',
            props: true
        },
        {
            path: 'leaderboard',
            component: pageLeaderboard,
            name: prefix + 'Leaderboard',
            meta: {
                requireAdmin: true
            }
        },
        {
            path: 'work/:profileId',
            component: pageWork,
            name: prefix + 'Work',
            meta: {
                requireAdmin: true
            },
            props: true
        },
        {
            path: 'moderate',
            component: pageModerate,
            name: prefix + 'Moderate'
        },
        {
            path: 'artist/:artistId',
            component: pageArtist,
            name: prefix + 'Artist',
            props: true
        },
        {
            path: 'artist/:artistId/upload',
            component: pageArtistUpload,
            name: prefix + 'ArtistUpload',
            props: true
        },
    ]
}

