
import axios               from "axios"
import { Vue, Component }  from 'vue-property-decorator'
import { Auth, clearAuth } from '@/core/auth'

@Component
export default class App extends Vue {
    created() {
    }
}
