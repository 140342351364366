
import { Vue, Component, Mixins } from 'vue-property-decorator'

import { Spinner }                from '@/components';

import Croppable                  from './Croppable.vue';
import FlagDialog                 from './FlagDialog.vue';
import GameMixin                  from './GameMixin';

@Component({
    components: { Spinner, FlagDialog, Croppable }
})
export default class Vote extends Mixins(GameMixin)
{
    flagActive: boolean = false;
    flaggedImage: any   = {};
    flagOptions = [
        { id: "INAPPROPRIATE", display: 'This image is not good for Roon' },
        { id: "NOTARTIST",     display: 'This image is not of ' + this.game.performerName },
    ]

    openFlagDialog(flaggedImage: any) {
        this.flaggedImage = flaggedImage 
        this.flagActive   = true;
    }

    flagGame(payload: any) {
        const body = {
            original:      'vote',
            gameType:      'flag',
            type:          'flag',
            cropId:         payload.info.cropDetails.resultId,
            uploadId:       null,
            data: {
                option:        payload.option,
                reason:        payload.reason,
            },
            flaggedImage: this.flaggedImage
        }
        this.submit('report', body);
    }

    itemsToLoad: number= 2;

    getImage(url: string) {
        const that: Vote = this;
        const img = new Image();
        img.onload = function() {
            that.itemLoaded(1)
        }
        img.src = url
        return img

    }

    clicked = -1
    emit(status: string, voted_for: number, delay: number = 300) {
        this.clicked = voted_for
        setTimeout(() => {
        if ( status === 'skip' ) {
            this.submit(status, { gameType: 'skip' });
            return;
        }
        const payload = {
            gameType: 'vote',
            winner:   this.game.images[voted_for!].cropDetails.resultId,
            duration: new Date().getTime() - this.startTime,//XXX
            voted_for
        }
        this.submit(status, payload);
        }, delay)
    }

    created() {
        this.itemsToLoad = this.game.images.length;
        for ( let image of this.game.images ) {
            this.getImage(image.url);
        }
    }

    start() {
        this.startTime = new Date().getTime();
        console.log('started')
    }
}
