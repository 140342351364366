
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class Spinner extends Vue {
    @Prop() label : string | undefined

    @Prop({
        default: 'vertical-blocks',
        validator(value: string) { return ['vertical-blocks'].indexOf(value) !== -1; }
    }) type! : string
}
