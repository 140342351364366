
import { Vue, Component } from 'vue-property-decorator'

import navbar             from '@/components/NavBarData';

import Manual             from './manual.vue';

@Component({components: { Manual } })
export default class ArtDirector extends Vue {
    $refs!: {
        manual: Manual // we don't have a type for a buefy b-dropdown :-(
    }

    handleHelp() {
        this.$refs.manual.show()
    }
    handleSearch(payload: any) {
        if ( payload.performer ) {
            this.$router.push({ name: 'ArtDirectorArtist', params: { artistId: payload.performer.roonPerformerId }})
        }
        return;
    }

    _timer? : number = 0;

    mounted() {
        navbar.showHelp = true;
        navbar.searchTypes = [ 'performers' ];
        navbar.$on('searched', this.handleSearch);
        navbar.$on('help', this.handleHelp);
    }
    destroyed() {
        navbar.showHelp = false;
        navbar.$off('searched', this.handleSearch);
        navbar.$off('help', this.handleHelp);
    }
}

