
import axios              from "axios";
import { Vue, Component, Prop } from 'vue-property-decorator';

import { Auth, refresh } from '@/core/auth';

@Component
export default class Work extends Vue {

    @Prop({required: true}) profileId: any;

    performers: any[]  = [];
    profile:    any = {};

    async getUserWork(profile: string) {
        try {
            const res        = await axios.get('/api/artdirector/1/admin/work', { params: { profile } });
            const json       = res.data;
            this.performers  = Object.values(json.performers);
            this.profile     = json.profile;
        } catch(err) {
            console.log(err);
        }
    }
    get recentPerformers() {
        return this.performers.filter((p:any) => p.recentPerformers)
    }
    get veryRecentPerformers() {
        return this.performers.filter((p:any) => p.veryRecentPerformers)
    }
    get allTimePerformers() {
        return this.performers.filter((p:any) => p.allTimePerformers)
    }
    get favoritePerformers() {
        return this.performers.filter((p:any) => p.favoritePerformers)
    }
    get directPerformers() {
        return this.performers.filter((p:any) => p.directPerformers)
    }

    created() {
        this.getUserWork(this.profileId)
    }
}
