
import axios                   from 'axios';
import { Vue, Component }      from 'vue-property-decorator'

import { Spinner, RoonSearch }  from '@/components';
import navbar                   from '@/components/NavBarData';

let ELEMENT_NODE = 1;
let TEXT_NODE = 3;
let LEFT_DIVIDER = '\uFEFF';
let RIGHT_DIVIDER = '\u200C';
//let LEFT_DIVIDER = '(';
//let RIGHT_DIVIDER = ')';

type Cursor = {
    hit:    boolean,
    range:  Range,
    tag:    HTMLElement | null,
    start:  boolean,
    end:    boolean,
};


@Component({components: { Spinner, RoonSearch } })
export default class TimelineDefaultView extends Vue {
    showSearch: boolean           = false
    popupRange: Range | undefined = undefined

    $refs!: {
        editor: any,
        edit: any,
        popup: any
    }

    async getMetadata() {
        try {
            const res  = await axios.get('/api/timelines/1/services/metadatatest')
            const json = await res.data;
            console.log(json);
        } catch(err) { 
            console.log(err)
        }
    }

    mounted() {
//        document.execCommand('defaultParagraphSeparator', false, 'p');
        this.getMetadata();
    }

    isSelection() {
        let sel = window.getSelection()!;
        if (sel.rangeCount == 0) return false;
        return !sel.getRangeAt(0).collapsed;
    }

    getCursor(): Cursor {
        // console.log('FUNC: getCursor')

        let sel = window.getSelection()!;
        let range = document.createRange();
        if (sel.rangeCount != 0) {
            const origrange = sel.getRangeAt(0);

            range = origrange.cloneRange();
            range.collapse(true)

            let n = range.startContainer;
            let i = 0;
            // console.log(i++, n, n.nodeName, n.className)

            if (n.nodeType == ELEMENT_NODE && (n as Element).className == "edit") {
                if (range.startOffset > 0 && (n.childNodes[range.startOffset-1] as HTMLElement).className == "editor-tag") {
                    return {
                        hit: false,
                        range: range,
                        start: false,
                        end: true,
                        tag: n.childNodes[range.startOffset-1] as HTMLElement,
                    };
                }
            }

            while (n) {
                if (n.nodeType == TEXT_NODE && (n.parentNode! as HTMLElement).className == "editor-tag") {
                    let t = n as Text;
                    // on edges
                    if ((t.previousSibling as Element)?.className == "taginner") {
                        return {
                            hit: range.startOffset == 0,
                            range: range,
                            start: false,
                            end: true,
                            tag: t.parentNode as HTMLElement,
                        };
                    } else if ((t.nextSibling as Element)?.className == "taginner") {
                        return {
                            hit: range.startOffset == t.data.length,
                            range: range,
                            start: true,
                            end: false,
                            tag: t.parentNode as HTMLElement,
                        };
                    }
                }
                if (n.nodeType == ELEMENT_NODE && (n as Element)?.className == "edit") break;
                n = n.parentNode as HTMLElement;
                // console.log(i++, n, n?.nodeName, n?.className)
            }
        }
        return { hit: false, range: range, start: false, end: false, tag: null };
    }

    keyup(e: KeyboardEvent) {
        // console.log('FUNC: keyup', e)
        this.fixup();
        if (e.key.startsWith("Arrow"))
            setTimeout(() => {
                let cursor = this.getCursor();
                if (cursor.hit) {
                    let sel = window.getSelection()!;
                    let range = document.createRange();
                    if (e.key == "ArrowLeft") {
                        range.setStartBefore(cursor.tag!);
                        // console.log('cursor update: moving to front', cursor)
                    } else if (e.key == "ArrowRight") {
                        range.setStartAfter(cursor.tag!);
                        // console.log('cursor update: moving to end', cursor)
                    } else if (cursor.start) {
                        range.setStartBefore(cursor.tag!);
                        // console.log('cursor update (auto): moving to front', cursor)
                    } else if (cursor.end) {
                        range.setStartAfter(cursor.tag!);
                        // console.log('cursor update (auto): moving to end', cursor)
                    }
                    sel.removeAllRanges();
                    sel.addRange(range);
                }
            });
    }

    fixup() {
        // console.log('FUNC: fixup')

        let l = this.$refs.edit.childNodes;
        let i = 0; 
        while (i < l.length) {
            let tag = l[i++];
            if (tag.className == "editor-tag") {
                tag.normalize()

                // console.log('checking', tag, tag.childNodes)

                if (tag.childNodes.length == 3) {
                    let t1 = tag.childNodes[0];
                    let t2 = tag.childNodes[1];
                    let t3 = tag.childNodes[2];

                    if (t2.className == 'taginner') {
                        if (t1.nodeType == TEXT_NODE) {
                            let c = LEFT_DIVIDER;
                            let t = t1;
                            if (t.data != c) {
                                let newText = t.data.replaceAll(c, '');
                                t.data = c;
                                tag.parentNode!.insertBefore(t=document.createTextNode(newText), tag);
                                // console.log("split out front", JSON.stringify(newText));

                                let sel = window.getSelection()!;
                                let range = document.createRange();
                                range.setStartAfter(t);
                                sel.removeAllRanges();
                                sel.addRange(range);
                                // console.log("setting cursor after", t)
                            }
                        } else {
                            console.log("OMG what is some element doing inside editor-tag (before)?", t1);
                            tag.replaceChild(document.createTextNode(LEFT_DIVIDER), t1)
                        }
                        if (t3.nodeType == TEXT_NODE) {
                            let t = t3;
                            let c = RIGHT_DIVIDER;
                            if (t.data != c) {
                                let newText = t.data.replaceAll(c, '');
                                t.data = c;
                                let nextn = tag.nextSibling
                                tag.parentNode!.insertBefore(t=document.createTextNode(newText), nextn);
                                // console.log("split out end", JSON.stringify(newText));

                                let sel = window.getSelection()!;
                                let range = document.createRange();
                                range.setStart(t, 1);
                                sel.removeAllRanges();
                                sel.addRange(range);
                                // console.log("setting cursor 1-in", t)
                            }
                        } else {
                            console.log("OMG what is some element doing inside editor-tag (after)?", t3);
                            tag.replaceChild(document.createTextNode(RIGHT_DIVIDER), t3)
                        }
                    }
                } else {
                    console.log(`OMG ${tag.childNodes.length} nodes after input`, tag.childNodes)
                }
                tag.normalize();
            }
        }
    }

    keydown(e: any) {
        // console.log('FUNC: keydown', e)

        if (e.key == "Enter") {
            document.execCommand('insertLineBreak');
            e.preventDefault();
//            document.execCommand('formatBlock', false, 'p');

//            this.addNewLine();
            return;
        }

        if (e.key == "Backspace") {
            let sel = window.getSelection()!;
            let range = sel.getRangeAt(0);
            if (!this.isSelection()) {
                let cursor = this.getCursor();
                if (cursor.end) {
                    cursor.tag!.parentNode!.removeChild(cursor.tag!);
                    e.preventDefault();
                }
                return;
            }
        }

        if (e.key == "@") {
            let sel = window.getSelection()!;

            if (sel.rangeCount != 0) {
                const origrange = sel.getRangeAt(0);

                this.showSearch = true;
                e.preventDefault();

                const range = origrange.cloneRange();
                range.collapse(true)

                let rect = range.getBoundingClientRect()
                if (range.collapsed && rect.top===0 && rect.left===0) {
                    let tmpNode = document.createTextNode('\ufeff');
                    range.insertNode(tmpNode);
                    rect = range.getBoundingClientRect();
                    tmpNode.remove();
                }

                let er = this.$refs.editor.getBoundingClientRect();

                let y = rect.y;
                let x = rect.x;

                this.popupRange = origrange;

                this.$refs.popup.setLocation(x + "px", y + "px");
                this.$refs.popup.setQuery(null);
                this.$refs.popup.focus()
            }
        }
    }

    addTag(range: Range, obj: any) {
//        console.log('FUNC: addTag')
        let sel = window.getSelection()!;

        const tag = document.createElement("span");
        tag.setAttribute("class", "editor-tag");

        const inner = document.createElement("span");
        inner.setAttribute("class", "taginner");
        inner.setAttribute("contenteditable", "false");
        inner.setAttribute("data-orig", obj.text);
        inner.setAttribute("data-id", obj.id);
        inner.innerText = obj.text;
        tag.appendChild(document.createTextNode(LEFT_DIVIDER));
        tag.appendChild(inner);
        tag.appendChild(document.createTextNode(RIGHT_DIVIDER));

        let c = range.startContainer!;
        if (c.nodeType == TEXT_NODE) {
            let t = c.parentNode! as Element;
            if (t.className == "editor-tag") {
                let p = t.parentNode!;

                let frag = document.createDocumentFragment();
                let blank = document.createTextNode(" ");
                let blank2 = document.createTextNode(" ");
                frag.appendChild(blank);
                frag.appendChild(tag);
                frag.appendChild(blank2);

                p.insertBefore(frag, t.nextSibling)

                // move to end
                range = document.createRange();
                range.setStartAfter(tag);
                sel.removeAllRanges();
                sel.addRange(range);

            } else {
                let n = c as Text;
                let before = document.createTextNode(n.data.slice(0, range.startOffset));
                let after = document.createTextNode(" " + n.data.slice(range.startOffset));

                let frag = document.createDocumentFragment();
                frag.appendChild(before);
                frag.appendChild(tag);
                frag.appendChild(after);

                n.parentNode!.replaceChild(frag, n);

                // move to end
                range = document.createRange();
                range.setStart(after, 1);
                sel.removeAllRanges();
                sel.addRange(range);
            }


        } else {
            let n = c;
            let blank = document.createTextNode(" ");

            let frag = document.createDocumentFragment();
            frag.appendChild(tag);
            frag.appendChild(blank);

            if (range.startOffset >= n.childNodes.length) {
                n.appendChild(frag)
            } else {
                n.insertBefore(frag, n.childNodes[range.startOffset]);
            }

            // move to end
            range = document.createRange();
            range.setStartAfter(blank);
            sel.removeAllRanges();
            sel.addRange(range);
        }
    }

    paste(e: ClipboardEvent) {
//        console.log('FUNC: paste')
        // we always want to paste plaintext in, not richtext
        var text = e.clipboardData!.getData('text/plain')
        document.execCommand('insertText', false, text)
    }

    handleSearch(payload: any | null) {
        let sel = window.getSelection()!;
        sel.removeAllRanges();
        sel.addRange(this.popupRange!);
        if (payload) {
            // console.log(payload.display, payload)
            this.popupRange!.deleteContents();

            this.addTag(this.popupRange!, {
                id: payload.performer.performerId,
                text: payload.display
            });
        } else {
            // console.log("no payload")
        }
        this.showSearch = false;
    }
}

