
import axios              from "axios";
import { Vue, Component, Prop, Watch }  from 'vue-property-decorator'

import { Auth }                         from '@/core/auth';

import ArtistUpload                     from '../components/ArtistUpload.vue';

@Component({components: { ArtistUpload } })
export default class Artist extends Vue {

    @Prop({required: true}) artistId!: string

    @Watch("artistId")
    onArtistIdChanged() {
        this.reload()
    }

    mounted() {
        this.reload();
    }

    get user() {
        return Auth.user;
    }

    artistData: {
        artistName:         string,
        artistId:           string,
        artistGenres:       string[],
        artistType:         string | null,
        systemImages:       number,
        userProvidedImages: number,
    } = {
        artistName:         '',
        artistId:           '',
        artistGenres:       [],
        artistType:         null,
        systemImages:       0,
        userProvidedImages: 0,
    }

    reload() {
        this.artistData   = {
            artistName:         '',
            artistId:           '',
            artistGenres:       [],
            artistType:         null,
            systemImages:       0,
            userProvidedImages: 0,
        }
        this.getArtistInfo(this.artistId);
    }

    async getArtistInfo(artistId: string) {
        try {
            const res       = await axios.get(`/api/artdirector/1/artists/${artistId}`);
            const json      = res.data;
            this.artistData = {...this.artistData, ...json, artistId};
        } catch (err) {
            console.log(err);
            //XXX
        }
    }
}
